import {type ID, type Response} from '../../../../../core/helpers'

export interface Cart {
  id?: ID
  email?: string | null
  avatar?: string | null
  firstName?: string | null
  lastName?: string | null

  birth?: Date | null
  address?: string | null
  countryOfOrigin?: string | null
  gender?: string | null
  phone?: string | null
  whatsapp?: string | null
  nationalitie?: string | null
  language?: string | null
  timeZone?: string | null
  role?: object | null
  createdAt?: string | null
  cart?: object | null
}

export interface CartImmigration {
  id?: ID
  cart?: object | null
}

export type CartQueryResponse = Response<Cart[]>

type toFormValuesType = (apiData: Cart) => Cart

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id,
  firstName: apiData.firstName || '',
  lastName: apiData.lastName || '',

  birth: apiData.birth != null ? new Date(apiData.birth) : null,
  address: apiData.address || 'Sincelejo',
  countryOfOrigin: apiData.countryOfOrigin || 'Colombia',
  gender: apiData.gender || 'M',
  phone: apiData.phone || '123',
  whatsapp: apiData.whatsapp || '123',

  nationalitie: apiData.nationalitie || 'CO',
  language: apiData.language || 'ES',
  timeZone: apiData.timeZone || '-5',
  cart: apiData.cart != null ? JSON.stringify(apiData.cart) : {} || {},
})

export const cartDetailsInitValues: Cart = {
  avatar: '/media/avatars/300-1.jpg',
  firstName: '',
  lastName: '',

  birth: new Date('1900-01-01'),
  address: '',
  countryOfOrigin: '',
  gender: '',
  phone: '',
  whatsapp: '',

  nationalitie: '',

  language: '',
  timeZone: '',
  cart: {},
}
