/* eslint-disable @typescript-eslint/no-unused-vars */
// jsx-a11y/anchor-is-valid

import AuthStore from '../auth/store/AuthStore'
import CartStore from '../app/modules/apps/cart/store/CartStore'
import ComposeTemplateStore from '../app/pages/messages/ComposeTemplate/store/ComposeTemplateStore'
import ImmigrationOptionStore from '../app/modules/apps/immigration/options/store/ImmigrationOption'
import Menustore from '../app/modules/apps/menus/store/MenuStore'
import MessageStore from '../app/modules/apps/messages/store/MessageStore'
import PriorityStore from '../app/modules/apps/priority/store/PriorityStore'
import ProfileStore from '../app/modules/apps/profile/store/ProfileStore'
import StaffStore from '../app/modules/apps/staff/store/StaffStore'
import StatusTagStore from '../app/modules/apps/statusTag/store/StatusTagStore'
import TranslationStore from '../app/modules/apps/translations/store/TranslationStore'
import UserStore from '../app/modules/apps/user/store/UserStore'
import UsersStore from '../app/modules/apps/users/store/UserStore'
import {createContext} from 'react'
import ContractsTempsStore from '../app/modules/apps/contract/store/ContractTempStore'
import {ProfileImmigration} from 'app/modules/apps/profile/core/_models'
import NotificationStore from 'app/modules/apps/notifications/store/NotificationStore'

export class RootStore {
  setProfileImmigration(ProfileImmigration: ProfileImmigration) {
    throw new Error('Method not implemented.')
  }
  authStore: AuthStore
  menustore: Menustore
  profileStore: ProfileStore
  messageStore: MessageStore
  staffStore: StaffStore
  translationStore: TranslationStore
  priorityStore: PriorityStore
  statusTagStore: StatusTagStore
  userStore: UserStore
  usersStore: UsersStore
  cartStore: CartStore
  immigrationOptionStore: ImmigrationOptionStore
  composeTemplateStore: ComposeTemplateStore
  contractTempsStore: ContractsTempsStore
  notificationStore: NotificationStore

  constructor() {
    this.authStore = new AuthStore(this)
    this.menustore = new Menustore(this)
    this.profileStore = new ProfileStore(this)
    this.messageStore = new MessageStore(this)
    this.staffStore = new StaffStore(this)
    this.translationStore = new TranslationStore(this)
    this.priorityStore = new PriorityStore(this)
    this.statusTagStore = new StatusTagStore(this)
    this.userStore = new UserStore(this)
    this.usersStore = new UsersStore(this)
    this.cartStore = new CartStore(this)
    this.immigrationOptionStore = new ImmigrationOptionStore(this)
    this.composeTemplateStore = new ComposeTemplateStore(this)
    this.contractTempsStore = new ContractsTempsStore(this)
    this.notificationStore = new NotificationStore(this)
  }
}

export const RootStoreContext = createContext(new RootStore())
