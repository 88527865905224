import {type ICartProducts} from '../products/_models'
import {type ID, type Response} from '../../../../../../core/helpers'
import {type IFormProduct} from '../formproducts/_models'

export interface PreOrder {
  id?: ID
  code: string
  paymentId: string | ''
  clientSecret: string | ''
  currency: string | ''
  date: Date | null
  total: number
  description: string
  sourceId: string | ''
}

export type PreOrderQueryResponse = Response<PreOrder[]>

type toFormValuesType = (apiData: PreOrder) => PreOrder

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id,
  code: apiData.code || '',
  paymentId: apiData.paymentId || '',
  clientSecret: apiData.clientSecret || '',
  currency: apiData.currency || '',
  date: apiData.date != null ? new Date(apiData.date) : null,
  total: apiData.total || 0,
  description: apiData.description || '',
  sourceId: apiData.sourceId || '',
})

export const preorderDetailsInitValues: PreOrder = {
  paymentId: '',
  code: '',
  clientSecret: '',
  currency: '',
  date: new Date(),
  total: 0,
  description: '',
  sourceId: '',
}

export interface PreOrderData {
  preOrder: PreOrder
  products: ICartProducts[]
  files: File[] | null
  forms: IFormProduct[] | []
  descriptionStripe: string
  discount: number
}

export interface Orders {
  id: string
  code: string
  orderTotal: number
  productId: string
  productName: string
  productDescription: string
  image: string
  unitPrice: number
  quantity: number
  total: number
  paymentId: string
  paymentIntentId: string
}

export type OrdersQueryResponse = Response<Orders[]>
