/* eslint-disable @typescript-eslint/no-unused-vars */

import {type ImmigrationOption, type ImmigrationOptionQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type Files} from '../../../../../../app/modules/apps/file/core/_models'
import {type ImmigrationService} from '../../services/core/_models'
import {type PaginationState} from '../../../../../../core/helpers'

const API_URL = process.env.REACT_APP_API_URL

const ImmigrationOption_URL = `${API_URL}/immigrationservicesoptions`
const ImmigrationOption_document_URL = `${API_URL}/immigrationservicesoptionsdocuments`

const getImmigrationOptions = async (
  paginationState?: PaginationState,
  immigrationOption?: Partial<ImmigrationOption>,
  immigrationServices?: Partial<ImmigrationService[]>
): Promise<ImmigrationOption[]> => {
  const dataPost = {
    immigrationService: immigrationServices,
  }

  return await axios
    .post(`${ImmigrationOption_URL}/all`, {
      immigrationService: immigrationServices?.map((item) => (item?.id ? item.id : '')),
      ...paginationState,
      ...immigrationOption,
    })
    .then((response: AxiosResponse<ImmigrationOptionQueryResponse>) => {
      return response.data.data as ImmigrationOption[]
    })
}
const getImmigrationOptionsNew = async (
  paginationState?: PaginationState,
  immigrationOption?: Partial<ImmigrationOption>,
  immigrationServices?: Partial<ImmigrationService[]>
): Promise<ImmigrationOption[]> => {
  const dataPost = {
    immigrationService: immigrationServices,
  }

  return await axios
    .post(`${ImmigrationOption_URL}/allNew`, {
      immigrationService: immigrationServices?.map((item) => (item?.id ? item.id : '')),
      ...paginationState,
      ...immigrationOption,
    })
    .then((response: AxiosResponse<ImmigrationOptionQueryResponse>) => {
      return response.data.data as ImmigrationOption[]
    })
}

// Save  ImmigrationOption
const saveImmigrationOptions = async (
  immigrationOption: ImmigrationOption
): Promise<ImmigrationOption[]> => {
  return await axios
    .post(`${ImmigrationOption_URL}`, {...immigrationOption})
    .then((response: AxiosResponse<ImmigrationOptionQueryResponse>) => {
      return response.data as ImmigrationOption[]
    })
}
// Save  ImmigrationOption
const updateImmigrationOptions = async (
  immigrationOption: ImmigrationOption
): Promise<ImmigrationOption[]> => {
  return await axios
    .patch(`${ImmigrationOption_URL}/${immigrationOption.id}`, {...immigrationOption})
    .then((response: AxiosResponse<ImmigrationOptionQueryResponse>) => {
      return response.data as ImmigrationOption[]
    })
}

const getImmigrationOptionFiles = async (id: string): Promise<Files[]> => {
  return await axios
    .get(`${ImmigrationOption_document_URL}/files/${id}`)
    .then((response: AxiosResponse<ImmigrationOptionQueryResponse>) => {
      return response.data as Files[]
    })
}

export {
  getImmigrationOptions,
  saveImmigrationOptions,
  updateImmigrationOptions,
  getImmigrationOptionFiles,
  getImmigrationOptionsNew,
}
