import {type Orders, type PreOrderData} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type PaymentIntent} from '../paimentintent/_models'
import {axiosErrorHandler} from '../../../../../../app/modules/errors/ApiError'
const API_URL = process.env.REACT_APP_API_URL
const PREORDER_URL = `${API_URL}/preorders`
const savePreOrder = (
  data: PreOrderData,
  getProgress: Function,
  type: string
): Promise<PaymentIntent> | undefined => {
  // imageToBase64(imageLarge)
  const formData = new FormData()
  data.files?.forEach((file: any) => {
    formData.append('files[]', file)
  })
  formData.append('length', data.files?.length.toString() || '0')
  formData.append('preOrder', JSON.stringify(data.preOrder))
  formData.append('descriptionStripe', data.descriptionStripe)
  formData.append('products', JSON.stringify(data.products))
  formData.append('forms', JSON.stringify(data.forms))
  formData.append('discount', JSON.stringify(data.discount))
  /* preOrder: PreOrder
  products: Array<ICartProducts>
  files: IFiles | null
  forms: IFormProduct[] | []
*/
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      getProgress(percentCompleted)
    },
  }
  return axios
    .post(`${PREORDER_URL}/save${type}`, formData, config)
    .then((response: AxiosResponse<any>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
  /*
  return axios.post(`${PREORDER_URL}/save/`, data).then((response: AxiosResponse) => {
    return response.data as PaymentIntent
  }) */
}


const saveSecond = (
  data: any,
  getProgress: Function,
  type: string
): Promise<PaymentIntent> | undefined => {
  // imageToBase64(imageLarge)
  const formData = new FormData()
  data.files?.forEach((file: any) => {
    formData.append('files[]', file)
  })
  formData.append('length', data.files?.length.toString() || '0')
  formData.append('preOrder', JSON.stringify(data.preOrder))
  formData.append('descriptionStripe', data.descriptionStripe)
  formData.append('products', JSON.stringify(data.products))
  formData.append('forms', JSON.stringify(data.forms))
  formData.append('discount', JSON.stringify(data.discount))
  formData.append('paymentIntent', JSON.stringify(data.paymentIntent))
  
  /* preOrder: PreOrder
  products: Array<ICartProducts>
  files: IFiles | null
  forms: IFormProduct[] | []
*/
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      getProgress(percentCompleted)
    },
  }
  return axios
    .post(`${PREORDER_URL}/savesecondcall${type}`, formData, config)
    .then((response: AxiosResponse<any>) => {
      return response.data
    })
    /* .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    }) */
  /*
  return axios.post(`${PREORDER_URL}/save/`, data).then((response: AxiosResponse) => {
    return response.data as PaymentIntent
  }) */
}

const saveFirst = (
  data: any,
  getProgress: Function,
  type: string
): Promise<any> | undefined => {
  // imageToBase64(imageLarge)
  const formData = new FormData()
  data.files?.forEach((file: any) => {
    formData.append('files[]', file)
  })
  formData.append('length', data.files?.length.toString() || '0')
  formData.append('preOrder', JSON.stringify(data.preOrder))
  formData.append('descriptionStripe', data.descriptionStripe)
  formData.append('products', JSON.stringify(data.products))
  formData.append('forms', JSON.stringify(data.forms))
  formData.append('discount', JSON.stringify(data.discount))

  /* preOrder: PreOrder
  products: Array<ICartProducts>
  files: IFiles | null
  forms: IFormProduct[] | []
*/
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      getProgress(percentCompleted)
    },
  }
  return axios
    .post(`${PREORDER_URL}/savefirstcall${type}`, formData, config)
    .then((response: AxiosResponse<any>) => {
      return response.data
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al subir archivo')
    })
  /*
  return axios.post(`${PREORDER_URL}/save/`, data).then((response: AxiosResponse) => {
    return response.data as PaymentIntent
  }) */
}
const getPreOrderByCode = (code: string): Promise<Orders[]> | undefined => {
  return axios.get(`${PREORDER_URL}/bycode/${code}`).then((response: AxiosResponse) => {
    return response.data as Orders[]
  })
}
const sendMailCode = (code: string): Promise<Orders[]> | undefined => {
  return axios.get(`${PREORDER_URL}/sendmail/${code}`).then((response: AxiosResponse) => {
    return response.data as Orders[]
  })
}
export {savePreOrder, getPreOrderByCode, sendMailCode,saveFirst,saveSecond}
