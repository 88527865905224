import {action, makeObservable, observable} from 'mobx'
import {type Menus} from '../core/_models'
import {type RootStore} from '../../../../../stores/rootStore'
import {getMenus} from '../core/_requests'

export default class Menustore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable menus: Menus[] = []
  @observable isFetching = false
  @observable isLoadingAvatar = false
  @observable isEditing = false
  @observable loadingInitial = false
  @observable imagePath = ''

  @action loadMenus = async () => {
    this.isFetching = true
    this.loadingInitial = true
    try {
      this.menus = await getMenus()

      this.isFetching = false
    } catch (error) {}
  }
}
