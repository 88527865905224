const ClearLocalStorageAndCookies = () => {
  const lastClearTime = localStorage.getItem('lastClearTime')
  const currentTime = new Date().getTime()

  if (!lastClearTime || currentTime - parseInt(lastClearTime) > 24 * 60 * 60 * 1000) {
    // Borrar los datos de localStorage
    localStorage.removeItem('document')

    // Borrar las cookies del sitio
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie

      while (name.charAt(0) === ' ') {
        name = name.substring(1)
      }
      if (name.indexOf('https://www.immiland.app') === 0) {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }
    }

    localStorage.setItem('lastClearTime', currentTime.toString())
  }
  // Renderizar algo si es necesario
  return null
}
export default ClearLocalStorageAndCookies
