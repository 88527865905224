import {type Menus, type MenusQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const MENUS_URL = `${API_URL}/menus`

const getMenus = async (): Promise<Menus[]> => {
  return await axios
    .post(`${MENUS_URL}/user`)
    .then((response: AxiosResponse<MenusQueryResponse>) => {
      return response.data as Menus[]
    })
}

export {getMenus}
