/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  type INotes,
  type MessageQueryResponse,
  type Messages,
  type MessagesQueryResponse,
  type MessagesUnread,
  type NoteQueryResponse,
  type NotesQueryResponse,
  toFormValues,
  toFormValuesNote,
} from './_models'
import axios, {type AxiosResponse} from 'axios'

import {type Staff} from '../../staff/core/_models'
import {UserModel} from '../../../../../auth/models/User/UserModel'
import {axiosErrorHandler} from '../../../../../app/modules/errors/ApiError'

const API_URL = process.env.REACT_APP_API_URL
const MESSAGES_URL = `${API_URL}/messages`
const MESSAGES_READ_URL = `${API_URL}/messagereads`
const NOTES_URL = `${API_URL}/notes`

const getMessages = async (query: string): Promise<MessagesQueryResponse> => {
  return await axios
    .get(`${MESSAGES_URL}?${query}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data
    })
}

const getMessagesUsers = async (query: string): Promise<MessagesQueryResponse> => {
  return await axios
    .get(`${MESSAGES_URL}?${query}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data
    })
}

const getMessagesIdUsers = async (id: string): Promise<Messages[]> => {
  return await axios
    .get(`${MESSAGES_URL}/user/${id}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}

const getMessagesIdAlternative = async (id: string,folder:string): Promise<Messages[]> => {
  return await axios
    .get(`${MESSAGES_URL}/user/${id}`,
    {
      params: {
        folder: folder,
      },
    })
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}



const getCounterIdUsers = async (id: string): Promise<Messages[]> => {
  return await axios
    .post(`${MESSAGES_URL}/usercounter`, {
      "id":id,
      "folder":"inbox"
      } )
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}



const getMyMessages = async (folder: string): Promise<Messages[]> => {
  return await axios
    .get(`${MESSAGES_URL}/me/${folder}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}

const getMyMessagesNew = async (folder: string, pagination: number): Promise<any> => {
  return await axios
    .get(`${MESSAGES_URL}/menew/${folder}`, {
      params: {
        pagination: pagination,
      },
    })
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as any;
    });
};

const getMyMessagesUnread = async (): Promise<MessagesUnread[]> => {
  return await axios
    .get(`${MESSAGES_URL}/unread`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as MessagesUnread[]
    })
}

const getMessagesUsersIdMessage = async (id: string): Promise<any[]> => {
  return await axios
    .get(`${MESSAGES_URL}/one/${id}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as any[]
    })
}

const getThread = async (id: string): Promise<any[]> => {
  return await axios
    .post(`${MESSAGES_URL}/getThread`,{id})
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as any[]
    })
}

const getAllMessages = async (search:any): Promise<Messages[]> => {
  return await axios
    .post(`${MESSAGES_URL}/getAll`,search)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}


const getMessagesUsersIdMessageExpanded = async (id: string): Promise<Messages[]> => {
  return await axios
    .get(`${MESSAGES_URL}/oneexpanded/${id}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}

const getMessagesIdUsersCount = async () => {
  return await axios.get(`${MESSAGES_URL}/count/all`).then((d) => {
    return d.data
  })
}

const updateMessages = async (messages: Messages, id: string) => {
  return await axios
    .patch(`${MESSAGES_URL}/${id}`, messages)
    .then((d: AxiosResponse<MessageQueryResponse>) => {
      return toFormValues(d.data as Messages)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar')
    })
}

const updateMessagesState = async (state: boolean, id: string) => {
  return await axios
    .patch(`${MESSAGES_URL}/state/${id}`, {state:state})
    .then((d: AxiosResponse<any>) => {
      return toFormValues(d.data as Messages)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar')
    })
}

const updateMessagesFavorite = async (state: boolean, id: string) => {
  return await axios
    .patch(`${MESSAGES_URL}/favorite/${id}`, {state:state})
    .then((d: AxiosResponse<any>) => {
      return toFormValues(d.data as Messages)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar')
    })
}

const updateMessagesUnread = async (state: boolean, id: string) => {
  return await axios
    .patch(`${MESSAGES_URL}/unreadmessage/${id}`, {state:state})
    .then((d: AxiosResponse<any>) => {
      return toFormValues(d.data as Messages)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar')
    })
}

const createReadMessage = async (id: string) => {
  return await axios
    .post(`${MESSAGES_READ_URL}/${id}`)
    .then((d) => {
      return true
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

 const createMessages = async (docdata: any): Promise<any> => {
  return await axios.post(`${MESSAGES_URL}`, docdata).then((d) => {
    return d.data
  })
}
const replyMessages = async (messages: any): Promise<Messages | null> => {
  console.log('estoy en la query')
  return await axios
    .post(`${MESSAGES_URL}`, messages)
    .then((d: AxiosResponse<MessageQueryResponse>) => {
      return toFormValues(d.data as Messages)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al crear mensaje')
      return null
    })
}

const createNotes = async (notes: INotes) => {
  return await axios
    .post(`${NOTES_URL}`, notes)
    .then((d: AxiosResponse<NoteQueryResponse>) => {
      return toFormValuesNote(d.data as INotes)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}


const updateDraft = async (message:any) => {
  return await axios
    .patch(`${MESSAGES_URL}/draft/${message.id}`, message)
    .then((d: AxiosResponse<any>) => {
      return toFormValues(d.data as Messages)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar')
    })
}

const sendDraft = async (message:any) => {
  return await axios
    .patch(`${MESSAGES_URL}/sentdraft/${message.id}`, message)
    .then((d: AxiosResponse<any>) => {
      return toFormValues(d.data as Messages)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar')
    })
}


const updateNotes = async (notes: INotes, id: string) => {
  return await axios
    .patch(`${NOTES_URL}/${id}`, notes)
    .then((d: AxiosResponse<NoteQueryResponse>) => {
      return toFormValuesNote(d.data as INotes)
    })
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const getNotes = async (query: string): Promise<NotesQueryResponse> => {
  return await axios.get(`${NOTES_URL}?${query}`).then((d: AxiosResponse<NotesQueryResponse>) => {
    return d.data
  })
}

const getNotesUser = async (): Promise<INotes[]> => {
  return await axios.get(`${NOTES_URL}/user`).then((d: AxiosResponse<NotesQueryResponse>) => {
    return d.data as INotes[]
  })
}

const getMessagesFilter = async (filter: string): Promise<Messages[]> => {
  return await axios
    .post(`${MESSAGES_URL}/filter/`, {filter})
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}

const getMessagesByConsultor = async (folder: string, staff: Staff): Promise<Messages[]> => {
  return await axios
    .post(`${MESSAGES_URL}/consultant`, {folder, staff})
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}

const getMessagesUnreadGeneral = async (staff: Staff): Promise<MessagesUnread[]> => {
  return await axios
    .post(`${MESSAGES_URL}/unreadgeneral`, {staff: staff.user != null ? staff.user.id : ''})
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as MessagesUnread[]
    })
}

const getMessagesUsersIdMessageGeneral = async (id: string): Promise<Messages[]> => {
  return await axios
    .get(`${MESSAGES_URL}/onegeneral/${id}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}

const getMessagesUnseen = async (id: string): Promise<Messages[]> => {
  return await axios
    .get(`${MESSAGES_URL}/seenonegeneral/${id}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => {
      return d.data as Messages[]
    })
}
export {
  getMessagesUsers,
  updateMessages,
  getMessages,
  createMessages,
  createNotes,
  updateNotes,
  getNotes,
  getMessagesIdUsers,
  getNotesUser,
  getMessagesIdUsersCount,
  createReadMessage,
  getMessagesUsersIdMessage,
  replyMessages,
  getMyMessages,
  getMyMessagesUnread,
  getMessagesFilter,
  getMessagesByConsultor,
  getMessagesUnreadGeneral,
  getMessagesUsersIdMessageGeneral,
  getMessagesUsersIdMessageExpanded,
  getMessagesUnseen,
  updateMessagesState,
  updateMessagesFavorite,
  updateDraft,
  sendDraft,
  getThread,
  getAllMessages,
  getMyMessagesNew,
  getCounterIdUsers,
  updateMessagesUnread,
  getMessagesIdAlternative
}
