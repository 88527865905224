import * as Sentry from '@sentry/react'
import axios, {type AxiosError} from 'axios'
import Swal from 'sweetalert2'

export const axiosErrorHandler = (error: AxiosError | Error, message: string) => {
  if (axios.isAxiosError(error)) {
    if (error.response != null) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // error.response.data
      // error.response.status
      // error.response.headers
      Sentry.captureException(JSON.stringify(error, error.response.data))
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      Sentry.captureException(JSON.stringify(error, error.request))
    } else {
      // Something happened in setting up the request that triggered an Error
      Sentry.captureException(error)
    }
  } else {
    Sentry.captureException(JSON.stringify(error))
  }

  Swal.fire({
    title: 'Error!',
    text: `Error: ${message} 😰 `,
    icon: 'error',
    confirmButtonText: 'Regresar',
  })
}
