 

import {type ProfileImmigration} from '../core/_models'
import {action, makeObservable, observable} from 'mobx'
import {type Profile as ProfileUser} from '../../../../../app/modules/apps/user/core/_models'
import {type RootStore} from '../../../../../stores/rootStore'
import {getProfileImmigrationById} from '../../profile/core/_requests'
import {getUserProfileById} from '../core/_requests'
import {profileDetailsInitValues} from '../core/_models'

export default class UserStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable user: ProfileUser | null = null
  @observable profileImmigration: ProfileImmigration = profileDetailsInitValues
  @observable isLoadingUser = false

  @action getUser = async (userId: string) => {
    this.user = null
    this.isLoadingUser = true

    try {
      const user = await getUserProfileById(userId)

      if (user != null) {
        this.user = user
      }
    } catch (error) {}

    this.isLoadingUser = false
  }

  @action getpProfile = async (userId: string) => {
    this.isLoadingUser = true
    this.profileImmigration = {}
    
    try {
      const profileImmigration = await getProfileImmigrationById(userId)

      if (profileImmigration != null) {
        this.profileImmigration = profileImmigration
      }
    } catch (error) {}

    this.isLoadingUser = false
  }

}
