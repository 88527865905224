import './index.css'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import AppRoutes from '../src/core/routing/AppRoutes'
import CustomProvider from '../src/core/providers/CustomProvider'
import ReactDOM from 'react-dom/client'
import { ReactQueryDevtools } from 'react-query/devtools'
import ClearLocalStorageAndCookies from './cookiesKiller'

Chart.register(...registerables)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

// Add meta viewport tag dynamically
const metaViewport = document.createElement('meta')
metaViewport.name = 'viewport'
metaViewport.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
document.head.appendChild(metaViewport)

root.render(
  <QueryClientProvider client={queryClient}>
    <CustomProvider>
      <ClearLocalStorageAndCookies />
      <AppRoutes />
    </CustomProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
