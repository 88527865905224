import 'react-toastify/dist/ReactToastify.css'
import React, {type FC} from 'react'
import {ToastContainer} from 'react-toastify'

interface CustomProviderProps {
  children: React.ReactNode
}
const CustomProvider: FC<CustomProviderProps> = ({children}) => {
  return (
    <>
      <ToastContainer />
      {children}
    </>
  )
}

export default CustomProvider
