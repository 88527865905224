/* eslint-disable @typescript-eslint/no-unused-vars */
import {action, computed, makeObservable, observable, runInAction, toJS} from 'mobx'
import {type Profile} from '../core/_models'
import {type RootStore} from '../../../../../stores/rootStore'
import {getProfile} from '../../../../../app/modules/apps/profile/core/_requests'

export default class ProfileStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable
  profile!: Profile

  @observable submitting = false
  @observable isLoadingAvatar = false
  @observable isEditing = false
  @observable loadingInitial = false
  @observable imagePath = ''

  @computed get getProfileFromStore(): Profile {
    return this.profile
  }

  @action selectProfile = () => {
    const profile = this.getProfileFromStore
    return toJS(profile)
  }

  @action loadProfile = async (id: string) => {
    let profile = this.getProfileFromStore
    if (profile) {
      this.profile = profile
      return toJS(profile)
    } else {
      this.loadingInitial = true
      try {
        profile = {}
        return profile
      } catch (error) {
        runInAction(() => {
          this.loadingInitial = false
        })
      }
    }
  }

  @action editProfile = async (profile: Profile) => {
    this.submitting = true
    try {
      // await agent.Profiles.update(profile)
      this.profile = {...profile}
    } catch (error) {
      runInAction(() => {
        this.submitting = false
      })
    }
  }

  @action setIsEditing = async (edit: boolean) => {
    this.isEditing = edit
  }

  @action setIsLoadingAvatar = async (edit: boolean) => {
    this.isLoadingAvatar = edit
  }

  @action setProfile = async (profile: Profile) => {
    this.submitting = true

    try {
      this.profile = {}
      this.profile = {...profile}
    } catch (error) {
      runInAction(() => {
        this.submitting = false
      })
    }
  }

  @action reloadProfile = async () => {
    this.loadingInitial = true
    try {
      const profile = await getProfile()
      if (profile != null) {
        this.profile = profile
        this.imagePath = this.profile.photo?.path + '/' + this.profile.photo?.id
      }
    } catch (error) {}
  }
}
