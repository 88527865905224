import axios, {type AxiosResponse} from 'axios'
import {type Category} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CATEGORY_URL = `${API_URL}/categories`

const getCategories = (query: string): Promise<Category[]> | undefined => {
  return axios.get(`${CATEGORY_URL}/all?${query}`).then((response: AxiosResponse) => {
    return response.data.data as Category[]
  })
}

const getCategoriesByName = (name: string): Promise<Category> | undefined => {
  return axios.get(`${CATEGORY_URL}/byName/${name}`).then((response: AxiosResponse) => {
    return response.data as Category
  })
}

const createCategory = (category: Category): Promise<Category[]> | undefined => {
  return axios.post(`${CATEGORY_URL}/save`, {...category}).then((response: AxiosResponse) => {
    return response.data as Category[]
  })
}

const updateCategory = (category: Category): Promise<Category> | undefined => {
  return axios
    .patch(`${CATEGORY_URL}/${category.id}`, {...category})
    .then((response: AxiosResponse) => {
      return response.data as Category
    })
}

const getCategoryTableFilter = (): Promise<any> | undefined => {
  return axios.get(`${CATEGORY_URL}/all`).then((response: AxiosResponse) => {
    return response as any
  })
}

export {getCategories, createCategory, updateCategory, getCategoryTableFilter, getCategoriesByName}
