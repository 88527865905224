import {type CheckBoxOptions} from '../types'
import {type ImmigrationService} from '../../../../../app/modules/apps/immigration/services/core/_models'

export const tableOptions = (services: ImmigrationService[], options: CheckBoxOptions[]) => {
  const servicesStr = services.map((service) => {
    return `<strong>${service.name}:</strong> ${getOptionToString(
      service.id || '',
      options
    )} <br />   `
  })
  const td = `${servicesStr.join('')}`
  return td
}

const getOptionToString = (id: string, options: CheckBoxOptions[]) => {
  const option = options.filter((option) => option.immigrationService.id === id && option.checked)
  return option.map((item) => item.description).join(', ')
}
