/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  TranslationsQueryNewResponse,
  type IQueryParams,
  type Translations,
  type TranslationsPartial,
} from '../core/_models'
import {action, makeObservable, observable} from 'mobx'
import {
  checkAssignments,
  deleteFile,
  downloadFileTranslation,
  downloadFileTranslationPreview,
  downloadFileTranslationPreviewNew,
  getAllTranslation,
  getAllTranslationNew,
  getAllTranslationUser,
  getTranslations,
  getTranslationsResult,
  setStaffToTranslation,
  updateTranslation,
  uploadFiles,
  uploadFilesResult,
} from '../core/_requests'

import {type RootStore} from '../../../../../stores/rootStore'
import {Staff} from '../../staff/core/_models'
import {RiContactsBookLine} from 'react-icons/ri'

const querysParam: IQueryParams = {
  page: 1,
  limit: 50,
}
export default class TranslationStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable translations: Translations[] = []
  @observable translationsTotal = 0
  @observable translationsUser: Translations[] = []
  @observable translationsStaff: Translations[] = []
  @observable translationsFiles: Translations[] = []
  @observable translationsResult: Translations[] = []
 @observable translationsIdForm: string = ''
  @observable isLoadingTranslation = false
  @observable querysParam: IQueryParams = querysParam

  @action loadTranslations = async (queryParams: IQueryParams) => {
    this.isLoadingTranslation = true

    this.querysParam = queryParams
    try {
      await this.checkAssignments()
      const translations = await getAllTranslationNew(queryParams)
      if (translations) {
        this.translations = translations.data

        this.translationsTotal = translations.total
      }
    } catch (error) {}
    this.isLoadingTranslation = false
  }

  @action checkAssignments = async () => {
    this.isLoadingTranslation = true

    try {
      await checkAssignments()
    } catch (error) {}
    this.isLoadingTranslation = false
  }

  @action loadTranslationsUser = async () => {
    this.isLoadingTranslation = true
    try {
      const translations = await getAllTranslationUser()
      if (translations) {
        this.translationsUser = translations
      }
    } catch (error) {}
    this.isLoadingTranslation = false
  }

  @action setStaffToTranslation = async (staff: string, translation: string) => {
    this.isLoadingTranslation = true
    try {
      if (staff && translation) {
        const setted = await setStaffToTranslation(staff, translation)
        if (setted) {
          return setted
        }
      }
    } catch (error) {}
    return null
  }

  @action updateFieldTranslation = async (field: TranslationsPartial, translationId: string) => {
    this.isLoadingTranslation = true

    try {
      const setted = await updateTranslation(field, translationId)
      if (setted) {
        this.isLoadingTranslation = false
        this.loadTranslations(this.querysParam)
        return setted
      }
    } catch (error) {}
    this.isLoadingTranslation = false
    return null
  }

  @action uploadFiles = async (
    fileListObject: any,
    getProgress: Function,
    type: string,
    translationId: string
  ) => {
    this.isLoadingTranslation = true

    try {
      const setted = await uploadFiles(fileListObject, getProgress, type, translationId)
      if (setted) {
        this.isLoadingTranslation = false
        this.loadTranslationsUser()
        return setted
      }
    } catch (error) {}
    this.isLoadingTranslation = false
    return null
  }

  @action uploadFilesResult = async (
    fileListObject: any,
    getProgress: Function,
    type: string,
    translationId: string
  ) => {
    this.isLoadingTranslation = true

    try {
      const setted = await uploadFilesResult(fileListObject, getProgress, type, translationId)
      if (setted) {
        this.isLoadingTranslation = false

        return setted
      }
    } catch (error) {}
    this.isLoadingTranslation = false
    return null
  }

  @action getTranslation = async (translationId: string) => {
    this.isLoadingTranslation = true

    try {
      const traslationFiles = await getTranslations(translationId)
      if (traslationFiles) {
        this.translationsFiles = traslationFiles
      }
    } catch (error) {}
    this.isLoadingTranslation = false
    return null
  }

  @action getTranslationResult = async (translationId: string) => {
    this.isLoadingTranslation = true

    try {
      const traslationFiles = await getTranslationsResult(translationId)
      if (traslationFiles) {
        this.translationsResult = traslationFiles
      }
    } catch (error) {}
    this.isLoadingTranslation = false
    return null
  }

  @action downloadFile = async (translationId: string, getProgress: Function) => {
    this.isLoadingTranslation = true

    try {
      const traslationFiles = await downloadFileTranslation(translationId, getProgress)
      if (traslationFiles) {
        this.translationsFiles = traslationFiles
      }
    } catch (error) {}
    this.isLoadingTranslation = false
    return null
  }

  @action downloadFileNew = async (translationId: string) => {
    this.isLoadingTranslation = true
    try {
      return await downloadFileTranslationPreviewNew(translationId)
    } catch (error) {}
    // this.isLoadingTranslation = false
    return null
  }

  @action downloadFilePreview = async (translationId: string, getProgress: Function) => {
    // this.isLoadingTranslation = true

    try {
      return await downloadFileTranslationPreview(translationId, getProgress)
    } catch (error) {}
    // this.isLoadingTranslation = false
    return null
  }

  @action deleteFile = async (translationId: string) => {
    this.isLoadingTranslation = true

    try {
      const deleteResult = await deleteFile(translationId)
    } catch (error) {}
    this.isLoadingTranslation = false
    return null
  }

  @action isFinished = (translation: Translations) => {
    if (translation) {
      if (translation.status === 'finished') {
        return true
      }
    }

    return false
  }

  @action loadTranslationsIdForm = async (Id: string) => {
    this.translationsIdForm = Id 
    
  }
}
