import { axiosErrorHandler } from 'app/modules/errors/ApiError'
import {type UserModel} from '../../auth/models/User/UserModel'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/me`
export const LOGIN_URL = `${API_URL}/auth/email/login`
export const REGISTER_URL = `${API_URL}/auth/email/register`
export const REGISTER_TRANSLATION_URL = `${API_URL}/auth/email/register/translation`
export const RESET_URL = `${API_URL}/auth/reset/password`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot/password`
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/change/password`
export const REMINDER_PASSWORD_URL = `${API_URL}/auth/reminder`
export const USER = `${API_URL}/users`
// Server should return AuthModel
export async function login(email: string, password: string) {
  return await axios.post(`${LOGIN_URL}`, {
    email,
    password,
  })
}


export async function changeEmail(email: string) {
  return await axios.post(`${API_URL}/auth/changeemail`, {
    email,
  })
}

// Server should return AuthModel
export async function register(user: UserModel, password: string) {
  return await axios.post(REGISTER_URL, {
    ...user,
    password,
  })
}

export async function registerTranslation(user: UserModel, password: string) {
  return await axios.post(REGISTER_TRANSLATION_URL, {
    ...user,
    password,
  })
}


// Server should return AuthModel
export async function reset(hash: string, password: string, password_confirmation: string) {
  return await axios.post(RESET_URL, {
    hash,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(email: string) {
  return await axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function reminder(email: string) {
  return await axios.post<{result: boolean}>(REMINDER_PASSWORD_URL, {
    email,
  })
}
export async function getUserByToken(token: string) {
  return await axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export async function changeUserPassword(id: string, password: string) {
  return await axios.post(CHANGE_PASSWORD_URL, {
    id,
    password,
  })
}

export async function changeFirstAccess  (id: string)  {
  return await axios
    .post(`${USER}/changeFirstAccess`, {id:id})
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}
