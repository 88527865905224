import {type AuthModel} from '../AuthModel'
import {type UserAddressModel} from './UserAddressModel'
import {type UserCommunicationModel} from './UserCommunicationModel'
import {type UserEmailSettingsModel} from './UserEmailSettingsModel'
import {type UserPhoto} from './UserPhoto'
import {type UserSocialNetworksModel} from './UserSocialNetworksModel'

export enum RoleEnum {
  'admin' = 1,
  'user' = 2,
}

interface Role {
  id: number
  name: string
}

export interface UserModel {

  id: string
  username?: string
  email: string
  firstName?: string
  lastName?: string
  ocupation?: string
  companyName?: string
  phone?: string
  role?: Role
  pic?: string
  birth?: Date | null
  countryOfOrigin?: string
  gender?: string
  maritalStatus?: string
  nationalitie?: string
  socialId?: string
  whatsapp?: string
  language?: 'es' | 'en' | 'fr'
  timeZone?: string
  website?: 'https://immilandcanada.com'
  emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  communication?: UserCommunicationModel
  address?: UserAddressModel
  socialNetworks?: UserSocialNetworksModel
  photo?: UserPhoto
  createdAt?: Date
  utm?: any
  firstAccess?:boolean
  show_intro_video?: boolean
}

export const initialUser: UserModel = {
  id: '',
  username: '',

  email: '',
  firstName: '',
  lastName: '',

  countryOfOrigin: '',
  firstAccess:false
}
