 

import {type Cart, type CartImmigration, cartDetailsInitValues} from '../core/_models'
import {type ICartProducts, type Product} from '../core/products/_models'
import {type IFormProduct} from '../core/formproducts/_models'
import {
  type PreOrder,
  type PreOrderData,
  preorderDetailsInitValues,
} from '../core/preorders/_models'
import {action, computed, makeObservable, observable} from 'mobx'
import {getPreOrderByCode, saveSecond, savePreOrder, sendMailCode, saveFirst} from '../core/preorders/_requests'
import {getProductById, getProductsByCategory} from '../core/products/_requests'
import {type Category} from '../core/category/_models'
import {type IEmbedVars} from '../../../../../app/pages/Cart/Product/EmbedVars'
import {type PaymentIntent} from '../core/paimentintent/_models'
import {type RootStore} from '../../../../../stores/rootStore'
import {getCategories} from '../core/category/_requests'
import {nanoid} from '@reduxjs/toolkit'

export default class CartStore {
  rootStore: RootStore

  @observable id = ''
  @observable cart: ICartProducts[] = []
  @observable categories: Category[] = []
  @observable products: Product[] = []

  @observable preOrder: PreOrder = preorderDetailsInitValues
  @observable currentUser: Cart | null = null
  @observable cartImmigration: CartImmigration = cartDetailsInitValues
  @observable isLoadingCart = false
  @observable isLoadingCategories = false
  @observable isLoadingProducts = false
  @observable isLoadingPreorder = false
  @observable isLoadingProduct = false
  @observable openResumenDrawer = false
  @observable paymentIntent: PaymentIntent | null = null
  @observable files: File[] | null = null
  @observable filesPaypal: File[] | null = null
  @observable formsProducst: IFormProduct[] = []
  @observable numberPages = 0
  @observable source = ''
  @observable cuponPrice = 0
  @observable error = false
  @observable paymentItem :any= []
  @observable payment :any= []

  // Store embedded variables
  @observable
  productembed: Product | null = null

  @observable isEmbed = false
  @observable cartoptions: IEmbedVars = {
    background: '#fff',
    embed: false,
    color: '#000',
    primary: '#262833',
    source: '',
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
    this.loadCart()
  }

  @action getCategories = async (query: string) => {
    this.isLoadingCategories = true

    try {
      const categories = await getCategories(query)
      if (categories != null) {
        this.categories = categories
      }
    } catch (error) {}

    this.isLoadingCategories = false
  }

  @action getProductsByCategory = async (categoryId: string) => {
    this.isLoadingProducts = true

    try {
      const products = await getProductsByCategory(categoryId)
      if (products) {
        this.products = products
      }
    } catch (error) {}

    this.isLoadingProducts = false
  }

  @action setOpenResumenDrawer = async (value: boolean) => {
    this.openResumenDrawer = value
  }

  @action setCuponPrice = async (value: number) => {
    this.cuponPrice = value
  }

  loadCart() {
    try {
      const cart = JSON.parse(localStorage.getItem('cart')?.toString() ?? '[]')
      this.cart = cart
      const preorder = JSON.parse(localStorage.getItem('preorder')?.toString() ?? '{}')

      if (preorder.code.length !== 0) {
        this.preOrder = {...preorder}
      }
    } catch (erro) {
      this.preOrder = preorderDetailsInitValues
    }
  }

  @action addTocart = async (product: Product, cuantity: number) => {
    this.paymentIntent = null
    const cart = JSON.parse(localStorage.getItem('cart')?.toString() ?? '[]')
    try {
      this.generatePreorder()

      const cartItem = cart.find((item: any) => item.product.id === product.id)
      if (cartItem) {
        cartItem.quantity += cuantity
      } else {
        cart.push({product, quantity: cuantity, preOrder: this.preOrder.code})
      }
      localStorage.setItem('cart', JSON.stringify(cart))
      this.loadCart()
    } catch (error) {}
  }

  // change product price in cart
  @action changeProductPrice = async (product: Product, price: number) => {
    this.paymentIntent = null
    const cart = JSON.parse(localStorage.getItem('cart')?.toString() ?? '[]')
    try {
      const cartItem = cart.find((item: any) => item.product.id === product.id)
      if (cartItem) {
        cartItem.product.price = price
      }
      localStorage.setItem('cart', JSON.stringify(cart))
      this.loadCart()
    } catch (error) {}
  }

  generatePreorder = async () => {
    this.preOrder.code = nanoid()
    localStorage.setItem('preorder', JSON.stringify(this.preOrder))
  }

  @action removeFromCart = async (product: Product) => {
    this.paymentIntent = null
    const cart = JSON.parse(localStorage.getItem('cart')?.toString() ?? '[]')
    try {
      const cartItem = cart.find((item: any) => item.product.id === product.id)
      if (cartItem) {
        cart.splice(cart.indexOf(cartItem), 1)
      }
      localStorage.setItem('cart', JSON.stringify(cart))
      this.loadCart()
    } catch (error) {}
  }

  @computed get countProducts() {
    return this.cart?.length || 0
  }

  @action savePreOrder = async (type?: string) => {
    if (this.paymentIntent == null) {
      const cart = JSON.parse(localStorage.getItem('cart')?.toString() ?? '[]')
      try {
        let total = 0
        if (this.preOrder.code === '') {
          this.generatePreorder()
        }
        let quantity = 0
        let product = ''
        cart.forEach((item: any, index: number) => {
          total += item.product.price * item.quantity
          product += item.product.name + ','
          quantity += item.quantity
          cart[index].preOrder = this.preOrder.code
        })

        this.preOrder.total = total
        this.preOrder.sourceId = this.getSource()
        this.preOrder.description = `${product} : ${quantity} : ${total} : ${this.preOrder.code}`

        const preorder: PreOrderData = {
          preOrder: this.preOrder,
          products: this.cart,
          files: this.files,
          forms: this.formsProducst,
          descriptionStripe: `${product} : ${quantity} : ${total} : ${this.preOrder.code}`,
          discount: this.cuponPrice,
        }

        const paymentIntent = await savePreOrder(
          preorder,
          () => {
            // comentario para desactivar el linter
          },
          type || ''
        )

        if (paymentIntent != null) {
          this.paymentIntent = paymentIntent
        }
        return paymentIntent
      } catch (error) {}
      return null
    } else {
      return this.paymentIntent
    }
  }

  @action saveSecondCall = async (type?: string) => {
    if (true) {
      const cart = JSON.parse(localStorage.getItem('cart')?.toString() ?? '[]')
      try {
        let total = 0
        if (this.preOrder.code === '') {
          this.generatePreorder()
        }
        let quantity = 0
        let product = ''
        cart.forEach((item: any, index: number) => {
          total += item.product.price * item.quantity
          product += item.product.name + ','
          quantity += item.quantity
          cart[index].preOrder = this.preOrder.code
        })

        this.preOrder.total = total
        this.preOrder.sourceId = this.getSource()
        this.preOrder.description = `${product} : ${quantity} : ${total} : ${this.preOrder.code}`

        const preorder: any = {
          preOrder: this.preOrder,
          products: this.cart,
          files: this.files,
          forms: this.formsProducst,
          descriptionStripe: `${product} : ${quantity} : ${total} : ${this.preOrder.code}`,
          discount: this.cuponPrice,
          paymentIntent:this.paymentItem
        }

        const payment = await saveSecond(
          preorder,
          () => {
            // comentario para desactivar el linter
          },
          type || ''
        )

        if (payment != null) {
          this.payment = payment
        }
        return payment
      } catch (error) {}
      return null
    }
  }




  @action savePaymentItem = async (type?: string) => {
    if (this.paymentIntent == null) {
      const cart = JSON.parse(localStorage.getItem('cart')?.toString() ?? '[]')
      try {
        let total = 0
        if (this.preOrder.code === '') {
          this.generatePreorder()
        }
        let quantity = 0
        let product = ''
        cart.forEach((item: any, index: number) => {
          total += item.product.price * item.quantity
          product += item.product.name + ','
          quantity += item.quantity
          cart[index].preOrder = this.preOrder.code
        })

        this.preOrder.total = total
        this.preOrder.sourceId = this.getSource()
        this.preOrder.description = `${product} : ${quantity} : ${total} : ${this.preOrder.code}`

        const preorder: any = {
          preOrder: this.preOrder,
          products: this.cart,
          files: this.files,
          forms: this.formsProducst,
          descriptionStripe: `${product} : ${quantity} : ${total} : ${this.preOrder.code}`,
          discount: this.cuponPrice
        }

        const paymentIntent = await saveFirst(
          preorder,
          () => {
            // comentario para desactivar el linter
          },
          type || ''
        )

        if (paymentIntent != null) {
          this.paymentIntent = paymentIntent
        }
        return paymentIntent
      } catch (error) {}
      return null
    } else {
      return this.paymentIntent
    }
  }
  @action getPreoderByCode = async (code: string) => {
    this.isLoadingPreorder = true

    try {
      const preorder = await getPreOrderByCode(code)
      if (preorder != null) {
        return preorder
      }
    } catch (error) {}

    this.isLoadingPreorder = false
  }


  @action setPaymentItem = async (item:any) => {
    this.paymentItem = item
  }

  @action resetCart = async () => {
    this.paymentIntent = null
    this.openResumenDrawer = false
    this.cart = []
    this.preOrder = preorderDetailsInitValues
    this.cartImmigration = cartDetailsInitValues
    localStorage.removeItem('cart')
    localStorage.removeItem('preorder')
    this.loadCart()
    console.log("reset")
  }

  @action getProductById = async (id: string) => {
    this.isLoadingProduct = true

    try {
      const product = await getProductById(id)
      if (product) {
        return product
      }
    } catch (error) {}
    this.isLoadingProduct = false
    return null
  }

  @action setFormsProducts = async (data: IFormProduct | null) => {
    data == null ? (this.formsProducst = []) : this.formsProducst.push(data)
  }

  @action setfiles = async (data: any) => {
    this.files = data
  }

  @action setfilesPaypal = async (data: any) => {
    this.filesPaypal = data
  }

  @action cartOptions = async () => {
    const cartoptions = JSON.parse(localStorage.getItem('cartoptions')?.toString() ?? '[]')
    this.cartoptions = cartoptions
    this.isEmbed = cartoptions.embed
  }

  @action setProductEmbed = async (product: Product) => {
    this.productembed = product
  }

  // get source from cartoptions in localstorage
  @action getSource = () => {
    const cartoptions = JSON.parse(localStorage.getItem('cartoptions')?.toString() ?? '[]')

    this.source = cartoptions.source
    return cartoptions.source
  }

  @action sendMailPurchaseOrder = async (code: string) => {
    try {
      await sendMailCode(code)
    } catch (error) {}
  }

  @action setError = async (b: boolean) => {
    this.error = b
  }

  @action setNumberPages = async (pages: number) => {
    this.numberPages = pages
  }
}
