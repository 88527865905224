import {type ID, type Response} from '../../../../../core/helpers'

export interface Messages {
  id: ID
  subject: string
  content: string
  email: string
  to: object | null
  cc: object | null
  userId: string | '' // UUID user receives email
  user: string | '' // User name receives email
  fromId: string | '' // UUID user who send email
  fromUser: string | '' // UUID user who send email
  fromEmail: string | ''
  fromPath?: string | ''
  fromPathId?: string | ''
  fromName?: string | ''
  orderDetailId: string | '' // UUID order detail
  files: string[]
  type: string
  mr_id?: string | ''
  mr_unread?: string | ''
  mr_read?: string | ''
  created_at?: string | ''
  shared?: string[] | []
  attachment?: string | ''
  messageId?: string | null
  filePath?: string | null
  fileId?: string | null
  fileName?: string | null
  folder: string | null
  draft: boolean | false
  favorite: boolean | false
  files_info: any[] | []
  unread: boolean | false
}

export type MessageQueryResponse = Response<Messages>
export type MessagesQueryResponse = Response<Messages[]>

type toFormValuesType = (apiData: Messages) => Messages

export const toFormValues: toFormValuesType = (apiData): Messages => ({
  id: apiData.id || '',
  userId: apiData.userId || '',
  subject: apiData.subject || '',
  orderDetailId: apiData.orderDetailId || '',
  content: apiData.content || '',
  email: apiData.email || '',
  to: apiData.to,
  cc: apiData.cc != null ? apiData.cc : [],
  user: apiData.user || '',
  fromId: apiData.fromId || '',
  fromUser: apiData.fromUser || '',
  fromEmail: apiData.fromEmail || '',
  fromPath: apiData.fromPath || '',
  fromName: apiData.fromName || '',
  fromPathId: apiData.fromPathId || '',
  files: apiData.files || [],
  type: apiData.type || '',
  mr_id: apiData.mr_id || '',
  mr_unread: apiData.mr_unread || '',
  mr_read: apiData.mr_read || '',
  attachment: apiData.attachment || '',
  messageId: apiData.messageId || '',
  folder: apiData.folder || '',
  draft: apiData.draft || false,
  favorite: apiData.favorite || false,
  files_info: apiData.files_info || [],
  unread : apiData.unread || false,
})

export const MessagesDetailsInitValues: Messages = {
  content: '',
  subject: '',
  orderDetailId: '',
  userId: '',
  email: '',
  to: {},
  cc: {},
  user: '',
  files: [],
  type: '',
  attachment: '',
  fromId: '',
  fromUser: '',
  fromEmail: '',
  fromName: '',
  fromPath: '',
  fromPathId: '',
  messageId: '',
  folder: '',
  id: undefined,
  draft: false,
  favorite: false,
  files_info: [],
  unread: false
}

export interface IMessages {
  page: number
  limit: number
  startDate: string
  endDate: string
}

export interface INotes {
  id?: ID
  text: string | null
  userId: string | null
  orderDetailId: string | null
  fromId: string | null
}

export type NoteQueryResponse = Response<INotes>
export type NotesQueryResponse = Response<INotes[]>

type toFormValuesNotesType = (apiData: INotes) => INotes

export const toFormValuesNote: toFormValuesNotesType = (apiData) => ({
  id: apiData.id || '',
  text: apiData.text || '',
  userId: apiData.userId || '',
  orderDetailId: apiData.orderDetailId || '',
  fromId: apiData.fromId || '',
})

export const NotesDetailsInitValues: INotes = {
  text: '',
  userId: '',
  orderDetailId: '',
  fromId: '',
}

export interface MessagesUnread {
  folder: string
  count: number
}
