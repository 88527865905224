import {type Profile, type ProfileImmigration, toFormValues} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type Response} from '../../../../../core/helpers'
import {axiosErrorHandler} from '../../../../../app/modules/errors/ApiError'


const API_URL = process.env.REACT_APP_API_URL
const PROFILE_URL = `${API_URL}/auth/me`
const USER_URL = `${API_URL}/users`
const EMAIL_URL = `${API_URL}/users/profile/changeemail`
const PROFILE_URL_IMMIGRATION = `${API_URL}/profiles`

const getProfile = async (): Promise<Profile | undefined> => {
  return await axios.post(`${PROFILE_URL}`).then((response: AxiosResponse<Response<Profile>>) => {
    return toFormValues(response.data as Profile)
  })
}

const updateProfileBasic = async (profile: Profile) => {
  return await axios
    .patch(`${PROFILE_URL}`, profile)
    .then((response: AxiosResponse<Response<Profile>>) => response.data)

    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const updateEmailBasic = async (id: any, email: string) => {
  return await axios
    .patch(`${EMAIL_URL}`, {
      'email': email,
      'id': id,
    })
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .catch((error) => {
      if (error.response && error.response.data) {
        console.log("Error message:", error.response.data.message);
      } else {
        console.log('Unexpected error:', error);
      }
       axiosErrorHandler(error, 'Error al actualizar correo');
    });
}

const updateProfileBasicByUser = async (profile: Profile) => {
  return await axios
    .post(`${PROFILE_URL_IMMIGRATION}/userbasic/${profile.id}`, profile)
    .then((response: AxiosResponse<Response<Profile>>) => response.data)

    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}



const updateShowIntroVideo = async (id: any) => {
  const body = {id}
  return await axios
    .post(`${USER_URL}/introvideo`, body )
    .then((response: AxiosResponse<Response<Profile>>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const getProfileImmigration = async (): Promise<ProfileImmigration | undefined> => {
  return await axios
    .get(`${PROFILE_URL_IMMIGRATION}`)
    .then((response: AxiosResponse<Response<ProfileImmigration>>) => {
      return response.data as ProfileImmigration
    })
}

const updateProfileImmigration = async (profile: object) => {
  const data = {
    profile, // This is the body part
  }
  return await axios
    .patch(`${PROFILE_URL_IMMIGRATION}/update`, data)
    .then((response: AxiosResponse<Response<ProfileImmigration>>) => response.data)
    .then((response: Response<ProfileImmigration>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const updateProfileNew = async (profile: object) => {
  return await axios
    .patch(`${PROFILE_URL_IMMIGRATION}/updateNew`, profile)
    .then((response: AxiosResponse<Response<ProfileImmigration>>) => response.data)
    .then((response: Response<ProfileImmigration>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}
const updateProfileImmigrationAdmin = async (id: string, profile: object) => {
  const data = {
    profile, // This is the body part
  }
  return await axios
    .post(`${PROFILE_URL_IMMIGRATION}/updateadmin/${id}`, data)
    .then((response: AxiosResponse<Response<ProfileImmigration>>) => response.data)
    .then((response: Response<ProfileImmigration>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

const getProfileImmigrationById = async (id: string): Promise<ProfileImmigration | undefined> => {
  return await axios
    .post(`${PROFILE_URL_IMMIGRATION}/user`, {id})
    .then((response: AxiosResponse<Response<ProfileImmigration>>) => {
      return response.data as ProfileImmigration
    })
}

export {
  getProfile,
  updateProfileBasic,
  updateEmailBasic,
  getProfileImmigration,
  getProfileImmigrationById,
  updateProfileBasicByUser,
  updateProfileImmigrationAdmin,
  updateProfileNew,
  updateProfileImmigration,
  updateShowIntroVideo
}
