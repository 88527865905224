import {type ImmigrationLink, type ImmigrationLinkQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type Files} from '../../../../../../app/modules/apps/file/core/_models'
import {type ImmigrationService} from '../../services/core/_models'
import {type PaginationState} from '../../../../../../core/helpers'

const API_URL = process.env.REACT_APP_API_URL

const ImmigrationLink_URL = `${API_URL}/immigrationlinks`
const ImmigrationLink_document_URL = `${API_URL}/immigrationlinksdocuments`

const getImmigrationLink = async (
  paginationState?: PaginationState,
  immigrationOption?: Partial<ImmigrationLink>,
  immigrationServices?: Partial<ImmigrationService[]>
): Promise<ImmigrationLink[]> => {
  return await axios
    .post(`${ImmigrationLink_URL}/all`, {
      immigrationService: immigrationServices?.map((item) => (item?.id ? item.id : '')),
      ...paginationState,
      ...immigrationOption,
    })
    .then((response: AxiosResponse<ImmigrationLinkQueryResponse>) => {
      return response.data.data as ImmigrationLink[]
    })
}

// Save  ImmigrationLink
const saveImmigrationLink = async (
  immigrationLink: ImmigrationLink
): Promise<ImmigrationLink[]> => {
  return await axios
    .post(`${ImmigrationLink_URL}`, {...immigrationLink})
    .then((response: AxiosResponse<ImmigrationLinkQueryResponse>) => {
      return response.data as ImmigrationLink[]
    })
}
// Save  ImmigrationLink
const updateImmigrationLink = async (
  immigrationLink: ImmigrationLink
): Promise<ImmigrationLink[]> => {
  return await axios
    .patch(`${ImmigrationLink_URL}/${immigrationLink.id}`, {
      ...immigrationLink,
    })
    .then((response: AxiosResponse<ImmigrationLinkQueryResponse>) => {
      return response.data as ImmigrationLink[]
    })
}

const getImmigrationLinkFiles = async (id: string): Promise<Files[]> => {
  return await axios
    .get(`${ImmigrationLink_document_URL}/files/${id}`)
    .then((response: AxiosResponse<ImmigrationLinkQueryResponse>) => {
      return response.data as Files[]
    })
}

export {getImmigrationLink, saveImmigrationLink, updateImmigrationLink, getImmigrationLinkFiles}
