import {type ImmigrationElement, type ImmigrationElementQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type Files} from '../../../../../../app/modules/apps/file/core/_models'
import {type ImmigrationService} from '../../services/core/_models'
import {type PaginationState} from '../../../../../../core/helpers'

const API_URL = process.env.REACT_APP_API_URL

const ImmigrationElement_URL = `${API_URL}/immigrationselements`
const ImmigrationElement_document_URL = `${API_URL}/immigrationselementsdocuments`

const getImmigrationElement = async (
  paginationState?: PaginationState,
  immigrationElement?: Partial<ImmigrationElement>,
  immigrationServices?: Partial<ImmigrationService[]>
): Promise<ImmigrationElement[]> => {
  return await axios
    .post(`${ImmigrationElement_URL}/all`, {
      immigrationService: immigrationServices?.map((item) => (item?.id ? item.id : '')),
      ...paginationState,
      ...immigrationElement,
    })
    .then((response: AxiosResponse<ImmigrationElementQueryResponse>) => {
      return response.data.data as ImmigrationElement[]
    })
}

// Save  ImmigrationElement
const saveImmigrationElement = async (
  immigrationElement: ImmigrationElement
): Promise<ImmigrationElement[]> => {
  return await axios
    .post(`${ImmigrationElement_URL}`, {...immigrationElement})
    .then((response: AxiosResponse<ImmigrationElementQueryResponse>) => {
      return response.data as ImmigrationElement[]
    })
}
// Save  ImmigrationElement
const updateImmigrationElement = async (
  immigrationElement: ImmigrationElement
): Promise<ImmigrationElement[]> => {
  return await axios
    .patch(`${ImmigrationElement_URL}/${immigrationElement.id}`, {...immigrationElement})
    .then((response: AxiosResponse<ImmigrationElementQueryResponse>) => {
      return response.data as ImmigrationElement[]
    })
}

const getImmigrationElementsFiles = async (id: string): Promise<Files[]> => {
  return await axios
    .get(`${ImmigrationElement_document_URL}/files/${id}`)
    .then((response: AxiosResponse<ImmigrationElementQueryResponse>) => {
      return response.data as Files[]
    })
}

export {
  getImmigrationElement,
  saveImmigrationElement,
  updateImmigrationElement,
  getImmigrationElementsFiles,
}
