import {type Priority} from '../core/_models'
import {action, makeObservable, observable} from 'mobx'

import {type RootStore} from '../../../../../stores/rootStore'
import {getPriority} from '../core/_requests'

export default class PriorityStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
    this.loadPriority()
  }

  @observable
  priorities: Priority[] = []

  @observable isLoadingPriority = false

  @action loadPriority = async () => {
    this.isLoadingPriority = true
    if (this.priorities.length === 0) {
      try {
        const priorities = await getPriority()
        if (priorities) {
          this.priorities = priorities
        }
      } catch (error) {}
    }
    this.isLoadingPriority = false
  }
}
