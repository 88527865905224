 

import {
  type IQueryUsers,
  type IQueryUsersFilter,
  type Users,
  type UsersImmigration,
  type UsersQueryResponse,
  usersDetailsInitValues,
} from '../core/_models'
import {action, makeObservable, observable} from 'mobx'

import {type RootStore} from '../../../../../stores/rootStore'
import {getUserUsers, getUserByFilter, getUserNewQuery} from '../core/_requests'

export default class UsersStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable users: UsersQueryResponse | null = null
  @observable currentUser: Users | null = null
  @observable usersImmigration: UsersImmigration = usersDetailsInitValues
  @observable isLoadingUsers = false

  @action getUsers = async (search: IQueryUsers) => {
    this.isLoadingUsers = true

    try {
      const users = await getUserUsers(search)

      if (users != null) {
        this.users = users
      }
    } catch (error) {}

    this.isLoadingUsers = false
  }

  @action getUsersNewQuery = async (search: IQueryUsers) => {
    this.isLoadingUsers = true

    try {
      const users = await getUserNewQuery(search)

      if (users != null) {
        this.users = users
      }
    } catch (error) {}

    this.isLoadingUsers = false
  }

  @action getUsersFilter = async (search: IQueryUsersFilter) => {
    this.isLoadingUsers = true

    try {
      const users = await getUserByFilter(search)

      if (users != null) {
        this.users = users
      }
    } catch (error) {}

    this.isLoadingUsers = false
  }

  @action selectUser = async (users: Users | null) => {
    this.currentUser = users
  }
}
