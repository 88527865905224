 
import {action, makeObservable, observable} from 'mobx'
import {type RootStore} from '../../../../../stores/rootStore'
import {type ContactsTempsQueryResponse, type ContractsTemps} from '../_models'
import {type IQueryUsers} from '../../users/core/_models'
import {getAllContractsTempsTemps} from '../_requests'

export default class ContractsTempsStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable contractTemps: ContactsTempsQueryResponse | null = null
  @observable currentContractTemp: ContractsTemps | null = null
  @observable isLoadingContractTemp = false

  @action getContractTemps = async (search: IQueryUsers) => {
    this.isLoadingContractTemp = true

    try {
      const contracttemps = await getAllContractsTempsTemps(search)

      if (contracttemps != null) {
        this.contractTemps = contracttemps
      }
    } catch (error) {}

    this.isLoadingContractTemp = false
  }

  @action selectContractTemp = async (contractTemps: ContractsTemps | null) => {
    this.currentContractTemp = contractTemps
  }
}
