import {type ImmigrationFund, type ImmigrationFundQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type Files} from '../../../../../../app/modules/apps/file/core/_models'
import {type ImmigrationService} from '../../services/core/_models'
import {type PaginationState} from '../../../../../../core/helpers'

const API_URL = process.env.REACT_APP_API_URL

const ImmigrationFund_URL = `${API_URL}/immigrationsfunds`
const ImmigrationFunds_document_URL = `${API_URL}/immigrationsfundsdocuments`

const getImmigrationFund = async (
  paginationState?: PaginationState,
  immigrationServices?: Partial<ImmigrationService[]>
): Promise<ImmigrationFund[]> => {
  return await axios
    .post(`${ImmigrationFund_URL}/all`, {...paginationState, ...immigrationServices})
    .then((response: AxiosResponse<ImmigrationFundQueryResponse>) => {
      return response.data.data as ImmigrationFund[]
    })
}

// Save  ImmigrationFund
const saveImmigrationFund = async (
  immigrationFund: ImmigrationFund
): Promise<ImmigrationFund[]> => {
  return await axios
    .post(`${ImmigrationFund_URL}`, {...immigrationFund})
    .then((response: AxiosResponse<ImmigrationFundQueryResponse>) => {
      return response.data as ImmigrationFund[]
    })
}
// Save  ImmigrationFund
const updateImmigrationFund = async (
  immigrationFund: ImmigrationFund
): Promise<ImmigrationFund[]> => {
  return await axios
    .patch(`${ImmigrationFund_URL}/${immigrationFund.id}`, {...immigrationFund})
    .then((response: AxiosResponse<ImmigrationFundQueryResponse>) => {
      return response.data as ImmigrationFund[]
    })
}

const getImmigrationFundsFiles = async (id: string): Promise<Files[]> => {
  return await axios
    .get(`${ImmigrationFunds_document_URL}/files/${id}`)
    .then((response: AxiosResponse<ImmigrationFundQueryResponse>) => {
      return response.data as Files[]
    })
}

export {getImmigrationFund, saveImmigrationFund, updateImmigrationFund, getImmigrationFundsFiles}
