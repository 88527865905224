import {type ImmigrationRequirement, type ImmigrationRequirementQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type ImmigrationService} from '../../services/core/_models'
import {type PaginationState} from '../../../../../../core/helpers'
import {DocumentsProduct} from 'app/modules/apps/forms/formproducts/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const ImmigrationRequirement_URL = `${API_URL}/immigrationrequirements`
const ImmigrationRequirement_document_URL = `${API_URL}/immigrationrequirementsdocuments`

const getImmigrationRequirement = async (
  paginationState?: PaginationState,
  immigrationRequirement?: Partial<ImmigrationRequirement>,
  immigrationServices?: Partial<ImmigrationService[]>
): Promise<ImmigrationRequirement[]> => {
  return await axios
    .post(`${ImmigrationRequirement_URL}/all`, {
      immigrationService: immigrationServices?.map((item) => (item?.id ? item.id : '')),
      ...paginationState,
      ...immigrationRequirement,
    })
    .then((response: AxiosResponse<ImmigrationRequirementQueryResponse>) => {
      return response.data.data as ImmigrationRequirement[]
    })
}

// Save  ImmigrationRequirement
const saveImmigrationRequirement = async (
  immigrationRequirement: ImmigrationRequirement
): Promise<ImmigrationRequirement[]> => {
  return await axios
    .post(`${ImmigrationRequirement_URL}`, {...immigrationRequirement})
    .then((response: AxiosResponse<ImmigrationRequirementQueryResponse>) => {
      return response.data as ImmigrationRequirement[]
    })
}
// Save  ImmigrationRequirement
const updateImmigrationRequirement = async (
  immigrationRequirement: ImmigrationRequirement
): Promise<ImmigrationRequirement[]> => {
  return await axios
    .patch(`${ImmigrationRequirement_URL}/${immigrationRequirement.id}`, {
      ...immigrationRequirement,
    })
    .then((response: AxiosResponse<ImmigrationRequirementQueryResponse>) => {
      return response.data as ImmigrationRequirement[]
    })
}

const getImmigrationRequirementFiles = async (id: string): Promise<DocumentsProduct[]> => {
  return await axios
    .get(`${ImmigrationRequirement_document_URL}/files/${id}`)
    .then((response: AxiosResponse<ImmigrationRequirementQueryResponse>) => {
      return response.data as DocumentsProduct[]
    })
}

const getDocumentsByProducts = async (products: any): Promise<DocumentsProduct[]> => {
  return await axios
    .post(`${ImmigrationRequirement_document_URL}/products`, products)
    .then((response: AxiosResponse<ImmigrationRequirementQueryResponse>) => {
      return response.data as DocumentsProduct[]
    })
}
const softDeleteDocumentsProduct = async (id: string): Promise<any> => {
  return await axios
    .delete(`${ImmigrationRequirement_document_URL}/files/${id}`)
    .then((response) => {
      return response
    })
}

const saveImmigrationRequirementFiles = async (
  product: string,
  document: string
): Promise<DocumentsProduct[]> => {
  return await axios
    .post(`${ImmigrationRequirement_document_URL}`, {product: product, document: document})
    .then((response: AxiosResponse) => {
      return response.data as DocumentsProduct[]
    })
}

const getImmigrationRequirementFilesNotSelected = async (id: string | null): Promise<any> => {
  return await axios
    .get(`${ImmigrationRequirement_document_URL}/notselected/${id}`)
    .then((d: AxiosResponse<any>) => {
      return d.data as any
    })
}

const softImmigrationRequirementFiles = async (id: string): Promise<any> => {
  return await axios.delete(`${ImmigrationRequirement_document_URL}/${id}`).then((response) => {
    return response
  })
}

export {
  getImmigrationRequirement,
  saveImmigrationRequirement,
  updateImmigrationRequirement,
  getImmigrationRequirementFiles,
  softDeleteDocumentsProduct,
  saveImmigrationRequirementFiles,
  getImmigrationRequirementFilesNotSelected,
  softImmigrationRequirementFiles,
  getDocumentsByProducts,
}
