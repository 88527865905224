/* eslint-disable @typescript-eslint/no-unused-vars */
import {ProductResponse, type Product, type ProductQueryResponse, IQueryProducts} from './_models'
import axios, {type AxiosResponse} from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const PRODUCT_URL = `${API_URL}/products`
const VISAHELPERS_URL = `${API_URL}/visahelpers`
const getProductsByCategory = async (productId: string): Promise<Product[]> => {
  return await axios
    .get(`${PRODUCT_URL}/category/${productId}`)
    .then((response: AxiosResponse<ProductQueryResponse>) => {
      return response.data as Product[]
    })
}
const getProductById = async (id: string): Promise<Product> => {
  return await axios
    .get(`${PRODUCT_URL}/find/${id}`)
    .then((d: AxiosResponse<ProductQueryResponse>) => {
      return d.data as Product
    })
}
const createProduct = (product: Product): Promise<Product[]> | undefined => {
  const formData = new FormData()
  if (product.file != null) {
    formData.append('file', product.file)
  }
  let data: keyof Product
  for (data in product) {
    if (data === 'category') {
      formData.append(data, product.category?.id || '')
    } else if (data !== 'file') {
      formData.append(data, product[data] as string)
    }
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      // getProgress(percentCompleted)
    },
  }
  return axios.post(`${PRODUCT_URL}`, formData, config).then((response: AxiosResponse) => {
    return response.data as Product[]
  })
}


const createProductNewManager = (product: Product): Promise<Product[]> | undefined => {
  const formData = new FormData()
  if (product.file != null) {
    formData.append('file', product.file)
  }
  let data: keyof Product
  for (data in product) {
  if (data !== 'file') {
      formData.append(data, product[data] as string)
  }
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      // getProgress(percentCompleted)
    },
  }
  return axios.post(`${PRODUCT_URL}/productManager`, formData, config).then((response: AxiosResponse) => {
    return response.data as Product[]
  })
}

const createProductNew = (product: /* Product */any): Promise<Product[]> | undefined => {
  const formData = new FormData()
  if (product.file != null) {
    formData.append('file', product.file)
    formData.append('name', product.name)
    formData.append('description', product.description)
    formData.append('price', product.price)
    formData.append('category', product.category)
  }else{
    formData.append('name', product.name)
    formData.append('description', product.description)
    formData.append('price', product.price)
    formData.append('category', product.category)
  }
  
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      // getProgress(percentCompleted)
    },
  }
  return axios.post(`${PRODUCT_URL}`, formData, config).then((response: AxiosResponse) => {
    return response.data as Product[]
  })
}
const updateProduct = (product: Product): Promise<Product> | undefined => {
  const formData = new FormData()
  if (product.file != null) {
    formData.append('file', product.file)
  }
  let data: keyof Product
  for (data in product) {
    if (data === 'category') {
      formData.append(data, product.category?.id || '')
    } else if (data !== 'file') {
      let value
      if (typeof product[data] === 'object') {
        value = JSON.stringify(product[data])
      } else {
        value = product[data] as string
      }
      formData.append(data, value)
    }
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    },
  }
  return axios
    .patch(`${PRODUCT_URL}/all/${product.id}`, formData, config)
    .then((response: AxiosResponse) => {
      return response.data as Product
    })
}

const updateProductNew = (product: Product): Promise<Product> | undefined => {
  const formData = new FormData()
  if (product.file != null) {
    formData.append('file', product.file)
  }
  let data: keyof Product
  for (data in product) {
   if (data !== 'file') {
      let value
      if (typeof product[data] === 'object') {
        value = JSON.stringify(product[data])
      } else {
        value = product[data] as string
      }
      formData.append(data, value)
    }
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    },
  }
  return axios
    .patch(`${PRODUCT_URL}/allnew/${product.id}`, formData, config)
    .then((response: AxiosResponse) => {
      return response.data as Product
    })
}
const updateProductStatus = (product: Product): Promise<Product> | undefined => {
  return axios
    .patch(`${PRODUCT_URL}/status/${product.id}`, {...product})
    .then((response: AxiosResponse) => {
      return response.data as Product
    })
}
const getProducts = (query: string): Promise<Product[]> | undefined => {
  console.log(`${PRODUCT_URL}/all?${query}`)
  return axios.get(`${PRODUCT_URL}/all?${query}`).then((response: AxiosResponse) => {
    return response.data.data as Product[]
  })
}

const getAllProducts = (query: string): Promise<Product[]> | undefined => {
  console.log(`${PRODUCT_URL}/all-products?${query}`)
  return axios.get(`${PRODUCT_URL}/all-products?${query}`).then((response: AxiosResponse) => {
    return response.data.data as Product[]
  })
}

const getProductsTable = (query: string): Promise<ProductResponse> | undefined => {
  return axios.get(`${PRODUCT_URL}/allnew?${query}`).then((response: AxiosResponse) => {
    return response as unknown as ProductResponse
  })
}

const getProductsTableNewQuery = (query: IQueryProducts): Promise<ProductResponse> | undefined => {
  return axios
    .post(`${PRODUCT_URL}/productnewquery`, {...query})
    .then((response: AxiosResponse) => {
      return response as ProductResponse
    })
}

const createvisahelper = (param: any): Promise<ProductResponse> | undefined => {
  return axios
    .post(`${VISAHELPERS_URL}`, param)
    .then((response: AxiosResponse) => {
      return response as any
    })
}

const getvisahelpers = (productId: any): Promise<any> | undefined => {
  return axios
    .get(`${VISAHELPERS_URL}/${productId}`)
    .then((response: AxiosResponse) => {
      return response as any
    })
}

const getProductsTableFilter = (): Promise<any> | undefined => {
  return axios.get(`${PRODUCT_URL}/allFilter`).then((response: AxiosResponse<ProductResponse>) => {
    return response as any
  })
}

const getAllProduct = (query:string): Promise<Product[]> => {
  return axios.get(`${API_URL}/products/all/sortbycontratationId?${query}`).then((response: AxiosResponse) => {
    return response.data.data as Product[]
  })
}

export {
  getProductById,
  createProduct,
  updateProduct,
  getProductsByCategory,
  getProducts,
  getAllProducts,
  updateProductStatus,
  getProductsTable,
  getProductsTableFilter,
  getProductsTableNewQuery,
  createProductNew,
  updateProductNew,
  createProductNewManager,
  getAllProduct,
  createvisahelper,
  getvisahelpers
}
