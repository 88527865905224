 

import {type StatusTag} from '../core/_models'
import {action, makeObservable, observable} from 'mobx'
import {type RootStore} from '../../../../../stores/rootStore'
import {getStatusTag} from '../core/_requests'

export default class StatusTagStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
    this.loadStatusTag()
  }

  @observable statustags: StatusTag[] = []
  @observable isLoadingStatusTag = false

  @action loadStatusTag = async () => {
    this.isLoadingStatusTag = true
    if (this.statustags.length === 0) {
      try {
        const statustags = await getStatusTag()
        if (statustags) {
          this.statustags = statustags
        }
      } catch (error) {}
    }
    this.isLoadingStatusTag = false
  }
}
