import {type StatusTag, type StatusTagQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const STATUSTAG_URL = `${API_URL}/statustag`

const getStatusTag = async (): Promise<StatusTag[]> => {
  return await axios
    .get(`${STATUSTAG_URL}/all`)
    .then((d: AxiosResponse<StatusTagQueryResponse>) => {
      return d.data as StatusTag[]
    })
}

export {getStatusTag}
