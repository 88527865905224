/* eslint-disable @typescript-eslint/no-unused-vars */
import {type ImmigrationService, type ImmigrationServiceQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const ImmigrationService_URL = `${API_URL}/immigrationservices`

const getImmigrationServices = async (
  ImmigrationService: ImmigrationService
): Promise<ImmigrationService[]> => {
  return await axios
    .get(`${ImmigrationService_URL}`)
    .then((response: AxiosResponse<ImmigrationServiceQueryResponse>) => {
      return response.data.data as ImmigrationService[]
    })
}

const saveImmigrationServices = async (data: any): Promise<ImmigrationService[]> => {
  return await axios
    .post(`${ImmigrationService_URL}/saveservices`, data)
    .then((response: AxiosResponse<ImmigrationServiceQueryResponse>) => {
      return response.data.data as ImmigrationService[]
    })
}

export {getImmigrationServices, saveImmigrationServices}
