import {type Priority, type PriorityQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const PRIORITY_URL = `${API_URL}/priority`

const getPriority = async (): Promise<Priority[]> => {
  return await axios.get(`${PRIORITY_URL}/all`).then((d: AxiosResponse<PriorityQueryResponse>) => {
    return d.data as Priority[]
  })
}

export {getPriority}
