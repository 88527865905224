 

import {action, makeObservable, observable} from 'mobx'
import {type CheckBoxOptions} from '../types'
import {type ImmigrationService} from '../../../../../app/modules/apps/immigration/services/core/_models'
import {type RootStore} from '../../../../../stores/rootStore'
import {TableGenerator} from '../TableGenerator'
import {getImmigrationCost} from '../../../../../app/modules/apps/immigration/costs/core/_requests'
import {getImmigrationElement} from '../../../../../app/modules/apps/immigration/elements/core/_requests'
import {getImmigrationFund} from '../../../../../app/modules/apps/immigration/funds/core/_requests'
import {getImmigrationLink} from '../../../../../app/modules/apps/immigration/links/core/_requests'
import {getImmigrationOptions} from '../../../../../app/modules/apps/immigration/options/core/_requests'
import {getImmigrationRequirement} from '../../../../../app/modules/apps/immigration/requirements/core/_requests'
import {saveImmigrationServices} from '../../../../../app/modules/apps/immigration/services/core/_requests'

export default class ComposeTemplateStore {
  @observable immigrationServices: ImmigrationService[] = []
  @observable immigrationOption: CheckBoxOptions[] = []
  @observable immigrationRequirement: CheckBoxOptions[] = []
  @observable immigrationElement: CheckBoxOptions[] = []
  @observable immigrationFund: CheckBoxOptions[] = []
  @observable immigrationCost: CheckBoxOptions[] = []
  @observable immigrationLink: CheckBoxOptions[] = []
  @observable isLoadingUsers = false
  @observable content = ''

  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @action sendAllDataUser = async (user: string) => {
    if (this.immigrationServices.length > 0) {
      // get all this.immigrationServices checked
      // const services = this.immigrationServices.filter((service) => service.checked === true)

      const data = {
        user,
        options: this.immigrationOption.filter((option) => option.checked === true),
        requeriments: this.immigrationRequirement.filter((option) => option.checked === true),
        funds: this.immigrationFund.filter((option) => option.checked === true),
        elements: this.immigrationElement.filter((option) => option.checked === true),
        costs: this.immigrationCost.filter((option) => option.checked === true),
        links: this.immigrationLink.filter((option) => option.checked === true),
      }
      const result = await saveImmigrationServices(data)
      console.log(
        '🚀 ~ file: ComposeTemplateStore.ts ~ line 51 ~ ComposeTemplateStore ~ @actionsendAllDataUser= ~ result',
        result
      )
    } else {
      console.log(
        '🚀 ~ file: ComposeTemplateStore.ts ~ line 51 ~ ComposeTemplateStore ~ @actionsendAllDataUser= ~ No hay data '
      )
    }
  }

  @action getContent = async () => {
    const tableGenerator = TableGenerator({
      services: this.immigrationServices,
      options: this.immigrationOption,
      requeriments: this.immigrationRequirement,
      funds: this.immigrationFund,
      elements: this.immigrationElement,
      costs: this.immigrationCost,
      links: this.immigrationLink,
    })

    this.content = tableGenerator
    return tableGenerator
  }

  @action setImmigrationServices = async (immigrationServices: ImmigrationService[]) => {
    this.immigrationServices = immigrationServices
  }

  @action getImmigrationRequirements = async () => {
    try {
      this.immigrationRequirement = []
      const res = await getImmigrationRequirement(
        {
          page: 1,
          limit: 100,
        },
        {
          name: '',
        },
        this.immigrationServices
      )

      if (res) {
        // set res  to immigrationRequirement array with checked false
        this.immigrationRequirement = res.map((item) => ({...item, checked: false}))
      }
    } catch (error) {}
  }

  @action setImmigrationRequirementsCheked = async (
    checkBoxOptions: CheckBoxOptions,
    checked: boolean
  ) => {
    this.immigrationRequirement.map((item) => {
      if (item.id === checkBoxOptions.id) {
        item.checked = checked
      }
    })
  }

  @action getImmigrationOptions = async () => {
    try {
      this.immigrationOption = []
      const res = await getImmigrationOptions(
        {
          page: 1,
          limit: 100,
        },
        {
          name: '',
        },
        this.immigrationServices
      )

      if (res) {
        // set res  to immigrationOption array with checked false
        this.immigrationOption = res.map((item) => ({...item, checked: false}))
      }
    } catch (error) {}
  }

  @action setImmigrationOptionsCheked = async (
    checkBoxOptions: CheckBoxOptions,
    checked: boolean
  ) => {
    this.immigrationOption.map((item) => {
      if (item.id === checkBoxOptions.id) {
        item.checked = checked
      }
    })
  }

  @action getImmigrationFunds = async () => {
    try {
      this.immigrationFund = []
      const res = await getImmigrationFund({
        page: 1,
        limit: 100,
      })

      if (res) {
        // set res  to immigrationFund array with checked false
        this.immigrationFund = res.map((item) => ({...item, checked: false}))
      }
    } catch (error) {}
  }

  @action setImmigrationFundsCheked = async (
    checkBoxOptions: CheckBoxOptions,
    checked: boolean
  ) => {
    this.immigrationFund.map((item) => {
      if (item.id === checkBoxOptions.id) {
        item.checked = checked
      }
    })
  }

  @action getImmigrationElements = async () => {
    try {
      this.immigrationElement = []
      const res = await getImmigrationElement(
        {
          page: 1,
          limit: 100,
        },
        {
          name: '',
        },
        this.immigrationServices
      )

      if (res) {
        // set res  to immigrationElement array with checked false
        this.immigrationElement = res.map((item) => ({...item, checked: false}))
      }
    } catch (error) {}
  }

  @action setImmigrationElementsCheked = async (
    checkBoxOptions: CheckBoxOptions,
    checked: boolean
  ) => {
    this.immigrationElement.map((item) => {
      if (item.id === checkBoxOptions.id) {
        item.checked = checked
      }
    })
  }

  @action getImmigrationCosts = async () => {
    try {
      this.immigrationCost = []
      const res = await getImmigrationCost(
        {
          page: 1,
          limit: 100,
        },
        {
          name: '',
        },
        this.immigrationServices
      )

      if (res) {
        // set res  to immigrationCost array with checked false
        this.immigrationCost = res.map((item) => ({...item, checked: false}))
      }
    } catch (error) {}
  }

  @action setImmigrationCostsCheked = async (
    checkBoxOptions: CheckBoxOptions,
    checked: boolean
  ) => {
    this.immigrationCost.map((item) => {
      if (item.id === checkBoxOptions.id) {
        item.checked = checked
      }
    })
  }

  @action getImmigrationLinks = async () => {
    try {
      this.immigrationLink = []
      const res = await getImmigrationLink(
        {
          page: 1,
          limit: 100,
        },
        {
          name: '',
        },
        this.immigrationServices
      )

      if (res) {
        // set res  to immigrationLink array with checked false
        this.immigrationLink = res.map((item) => ({...item, checked: false}))
      }
    } catch (error) {}
  }

  @action setImmigrationLinksCheked = async (
    checkBoxOptions: CheckBoxOptions,
    checked: boolean
  ) => {
    this.immigrationLink.map((item) => {
      if (item.id === checkBoxOptions.id) {
        item.checked = checked
      }
    })
  }
}
