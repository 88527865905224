import {observable} from 'mobx'
import {type RootStore} from '../../../../../../stores/rootStore'

export default class ImmigrationOptionStore {
  rootStore: RootStore

  @observable id = ''

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
}
