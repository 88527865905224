import {type ID, type Response} from '../../../../../core/helpers'
import {type UserPhoto} from '../../../../../auth/models/User/UserPhoto'

export interface Profile {
  id?: ID
  email?: string | null

  avatar?: string | null
  firstName?: string | null
  lastName?: string | null
  birth?: Date | null
  address?: string | null
  countryOfOrigin?: string | null
  gender?: string | null
  phone?: string | null
  whatsapp?: string | null
  nationalitie?: string | null
  language?: string | null
  timeZone?: string | null
  role?: object | null
  createdAt?: string | null
  photo?: UserPhoto
}

export interface ProfileImmigration {
  id?: ID
  occupacion?: string
  numero_hijos?: string
  edad_hijos?: string
  profile?: object | null
  estadoCivil?: string | null
  rechazos_visa?: string | null
  estado_civil?: string
  conyuge?: string
  edadconyuge?: string
  conyuge_pais_residencia?: string
  conyuge_area_estudios?: string
  previousApplications?: string | null
  grado_academico?: string | null
  conyuge_grado_academico?: string
  conyuge_proeficiencia_ingles?: string
  conyuge_proeficiencia_frances?: string
  area_de_estudios?: string | null
  proeficiencia_en_ingles?: string
  proeficiencia_en_frances?: string
  historial_laboral?: string
  conyuge_historial_laboral?: string
  plicaciones_previas?: string
  tipo_aplicacion?: string
  emigrar_con_familia?: string
}

export type ProfileQueryResponse = Response<Profile[]>

type toFormValuesType = (apiData: Profile) => Profile

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id,
  firstName: apiData.firstName || '',
  lastName: apiData.lastName || '',

  birth: apiData.birth != null ? new Date(apiData.birth) : null,
  address: apiData.address || '',
  countryOfOrigin: apiData.countryOfOrigin || '',
  gender: apiData.gender || '',
  phone: apiData.phone || '',
  whatsapp: apiData.whatsapp || '',

  nationalitie: apiData.nationalitie || '',
  language: apiData.language || '',
  timeZone: apiData.timeZone || '',
  photo: apiData.photo,
})

export const profileDetailsInitValues: Profile = {
  avatar: '/media/avatars/300-1.jpg',
  firstName: '',
  lastName: '',

  birth: new Date('1900-01-01'),
  address: '',
  countryOfOrigin: '',
  gender: '',
  phone: '',
  whatsapp: '',

  nationalitie: '',

  language: '',
  timeZone: '',
}
