import {
  type Messages,
  type MessagesUnread,
} from '../../../../../app/modules/apps/messages/core/_models'
import {action, computed, makeObservable, observable} from 'mobx'
import {
  getAllMessages,
  getCounterIdUsers,
  getMessagesByConsultor,
  getMessagesIdAlternative,
  getMessagesIdUsers,
  getMessagesUnreadGeneral,
  getMyMessages,
  getMyMessagesNew,
  getMyMessagesUnread
} from '../../../../../app/modules/apps/messages/core/_requests'
import {type RootStore} from '../../../../../stores/rootStore'
import {type Staff} from '../../staff/core/_models'

export default class MessageStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable message!: Messages
  @observable messages: Messages[] = []
  @observable messagesNew: any = []
  @observable messagesGeneral: Messages[] = []
  @observable messagesUnread: MessagesUnread[] = []
  @observable messagesUnreadGeneral: MessagesUnread[] = []
  @observable submitting = false
  @observable isLoadingMessages = false
  @observable isEditing = false
  @observable loadingInitial = false
  @observable imagePath = ''
  @observable messageSelected = false
  @observable searchWord : any=''
  @observable newFetchSearchWord : any=''
  @observable page : any=1
  @observable currentMemberMessages: any = []
  @observable countMessagesAuth: any = []
  @observable countMessagesClient: any = []
  @computed get countMessagesNotRead(): number {
    let messagesUnread = 0

    this.messagesUnread.forEach((message: MessagesUnread) => {
      messagesUnread += message.count
    })
    return messagesUnread
  }

  @computed get countMessagesNotReadGeneral(): number {
    let messagesUnread = 0
    this.messagesUnreadGeneral.forEach((message: MessagesUnread) => {
      messagesUnread += message.count
    })

    return messagesUnread
  }

  @action countMessagesNotReadFolder(folder: string): number {
    let messagesUnread = 0

    this.messagesUnread.forEach((message: MessagesUnread) => {
      if (message.folder === folder) {
        messagesUnread += message.count
      }
    })

    return messagesUnread
  }

  @action loadMessagesUnread = async () => {
    try {
      this.messagesUnread = await getMyMessagesUnread()
    } catch (error) {}
  }

  @action loadMessagesCurrentMemberMessages = async (id:any) => {
    try {
      this.currentMemberMessages = await getMessagesIdUsers(id)
    } catch (error) {}
  }

  @action loadMessagesCurrentMemberMessagesAlternative = async (id:any,folder:string) => {
    try {
      this.currentMemberMessages = await getMessagesIdAlternative(id,folder)
    } catch (error) {}
  }

  @action loadCounterMessagesAuth = async (id:any) => {
    try {
      const result:any = await getCounterIdUsers(id)
      this.countMessagesAuth=result.data as any
    } catch (error) {}
  }

  @action loadCounterMessagesClient = async (id:any) => {
    try {
      const result:any = await getCounterIdUsers(id)
      this.countMessagesClient=result.data as any
    } catch (error) {}
  }

  @action setMessage = async (messagesSearch:any) => {
    this.messages=messagesSearch
  }

  @action setPage = async (page:Number) => {
    this.page=page
  }

  @action setWord = async (word:any) => {
    this.searchWord=word
  }

  @action setWordNewFetch = async (word:any) => {
    this.newFetchSearchWord =word
  }
  @action setMessageNew = async (messagesSearch:any) => {
    this.messagesNew=messagesSearch
  }

  @action setMessageSelected = async (state:any) => {
    this.messageSelected=state
  }


  @action loadMessages = async (folder: string) => {
    this.messages = []
    this.loadingInitial = true
    try {
      this.messages = await getMyMessages(folder)
    } catch (error) {}
  }

  @action loadMessagesNew = async (folder: string,pagination:number) => {
    if(this.searchWord===undefined ||this.searchWord===""){
    this.messages = []
    this.messagesNew = []
    this.loadingInitial = true
    try {
      console.log("pagina",folder,pagination,this.searchWord)
      this.messagesNew = await getMyMessagesNew(folder,pagination)
    } catch (error) {}
  }else{
    this.messages = []
    this.messagesNew = []
    this.loadingInitial = true
    try {
      console.log("pagina",folder,pagination)
      const data = {
        search: this.searchWord,
        pagination: pagination,
      };
      this.messagesNew = await getAllMessages(data)
    } catch (error) {}
  }
  }

  @action setMessagesRead = async (id: string) => {
    this.messages.forEach((item: Messages) => {
      if (item.id === id) {
        item.mr_id = 'true'
      }
      return item
    })
    this.loadMessagesUnread()
  }

  @action loadMessagesByConsultor = async (folder: string, staff: Staff) => {
    this.messagesGeneral = []
    this.loadingInitial = true
    try {
      this.messagesGeneral = await getMessagesByConsultor(folder, staff)
    } catch (error) {}
  }

  @action loadMessagesUnreadGeneral = async (staff: Staff) => {
    try {
      this.messagesUnreadGeneral = await getMessagesUnreadGeneral(staff)
    } catch (error) {}
  }
}
