import {action, computed, makeObservable, observable} from 'mobx'
import {getStaffs, getStaffsTo} from '../core/_requests'
import {type RootStore} from '../../../../../stores/rootStore'
import {type Staff} from '../core/_models'

interface StaffsSelect {
  text: string
  value: string
}
export default class StaffStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable staffsTo: Staff[] = []
  @observable staffs: Staff[] = []
  @observable isLoadingStaff = false
  @observable isLoadingAvatar = false
  @observable isEditing = false
  @observable loadingInitial = false
  @observable imagePath = ''

  @computed get getStaffsSelect(): StaffsSelect[] {
    const staffsSelect: StaffsSelect[] = []
    this.staffsTo.forEach((staff: Staff) => {
      staffsSelect.push({
        text: staff.name || '',
        value: staff.id || '',
      })
    })

    return staffsSelect
  }

  // Used to get the user for to or cc emails
  @action loadStaffsTo = async (value: string) => {
    this.isLoadingStaff = true
    this.loadingInitial = true
    try {
      this.staffsTo = await getStaffsTo(value)

      this.isLoadingStaff = false
    } catch (error) {}
  }

  @action loadStaffs = async (staff: Staff) => {
    this.isLoadingStaff = true
    this.loadingInitial = true
    try {
      this.staffs = await getStaffs(staff)

      this.isLoadingStaff = false
    } catch (error) {}
  }
}
