/* eslint-disable @typescript-eslint/no-unused-vars */
import {type CheckBoxOptions} from '../types'
import {type ImmigrationService} from '../../../../../app/modules/apps/immigration/services/core/_models'
import {tableOptions} from './TableOptions'

interface Props {
  services?: ImmigrationService[]
  options?: CheckBoxOptions[]
  requeriments?: CheckBoxOptions[]
  funds?: CheckBoxOptions[]
  elements?: CheckBoxOptions[]
  costs?: CheckBoxOptions[]
  links?: CheckBoxOptions[]
}

const TableGenerator = ({
  services = [],
  options = [],
  requeriments = [],
  funds = [],
  elements = [],
  costs = [],
  links = [],
}: Props) => {
  const table = `
  <table class="table-auto">
    <tbody>
    <tr>
            <td><strong>OPCIONES MIGRATORIAS EVALUADAS:</strong></td>
            <td> ${tableOptions(services, options)}  </td>

        </tr>
        <tr>
            <td><strong>REQUISITOS:</strong></td>
            <td> ${tableOptions(services, requeriments)}  </td>

        </tr>
        <tr>
            <td><strong>MONTO DE DINERO REQUERIDO:</strong></td>
            <td>${tableOptions(services, funds)} </td>
        </tr>
        <tr>
            <td><strong>ELEMENTOS PARA TRABAJAR ANTES DE APLICAR AVISADO:</strong></td>
            <td>${tableOptions(services, elements)}</td>
        </tr>
        <tr>
            <td><strong>COSTO DE SERVICIOS Y ASESORIA LEGAL:</strong></td>
            <td>${tableOptions(services, costs)}</td>
        </tr>
        <tr>
            <td><strong>LINKS IMPORTANTES:</strong></td>
            <td>${tableOptions(services, links)}</td>
        </tr>
        <tr>
        <td><strong>NOTAS</strong></td>
          <td>
            </br></br></br>
          </td>
        </tr>



    </tbody>
</table>
`
  return table
}

const getOptionCheked = (options: CheckBoxOptions[]) => {
  const optionCheked = options.filter((option) => option.checked)
  return optionCheked
}

export {TableGenerator}
