import {
  type IQueryUsers,
  type Users,
  type UsersQueryResponse,
  type IQueryUsersFilter,
  UserBasic,
} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type UserModel} from '../../../../../auth/models/User/UserModel'
import {
  type sendingBlueTemplates,
  type sendingBlueTemplatesQueryResponse,
} from '../../sendinBlueTemplates/_models'

const API_URL = process.env.REACT_APP_API_URL
const USERS_URL = `${API_URL}/users`
const ASSOCIATED_USERS_URL = `${API_URL}/associatedusers`;
const getUserUsers = (search: IQueryUsers): Promise<UsersQueryResponse> | undefined => {
  return axios
    .post(`${USERS_URL}/userslist`, {...search})
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getUserNewQuery = (search: IQueryUsers): Promise<UsersQueryResponse> | undefined => {
  return axios
    .post(`${USERS_URL}/userslistNewQuery`, {...search})
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}


const getUserNewQueryDashboard = (search: IQueryUsers): Promise<UsersQueryResponse> | undefined => {
  return axios
    .post(`${USERS_URL}/userslistNewQueryDashboard`, {...search})
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getPartnetship = async (): Promise<any> => {
  return await axios.get(`${USERS_URL}/partnership/all`).then((d: AxiosResponse<any>) => {
    return d.data as any
  })
}
const getUserByFilter = (
  searchFilter: IQueryUsersFilter
): Promise<UsersQueryResponse> | undefined => {
  return axios
    .post(`${USERS_URL}/userslistfilter`, {...searchFilter})
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getUserUsersById = async (userId: string): Promise<Users | undefined> => {
  return await axios.post(`${USERS_URL}/data`, {id: userId}).then((response: AxiosResponse) => {
    const frmval = response.data

    return frmval[0] as Users
  })
}

const getUserUsersByEmail = async (email: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/email`, {email})
    .then((response: AxiosResponse) => response.data)
}

const saveUserBasic = async (user: UserModel): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/savebasic`, {...user})
    .then((response: AxiosResponse) => response.data)
}

const sendEmailBlueTemplate = async (
  usersIds: React.Key[],
  templateNumber: string
): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendemailbluetemplate`, {
      usersIds,
      templateNumber,
    })
    .then((response: AxiosResponse) => response.data)
}

const sendEmailBlueTemplateExtra = async (
  staff: string,
  email: string
): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailBlueTemplateExtra`, {
      staff,
      email,
    })
    .then((response: AxiosResponse) => response.data)
}

const sendEmailSwitchAccount = async (userOneEmail: string, userTwoEmail: string): Promise<any> => {
  try {
    const response = await axios.post(`${USERS_URL}/sendEmailSwitchAccount`, {
      userOneEmail,
      userTwoEmail,
    });

    console.log('Correo enviado con éxito:', response.data);

    return response.data;
  } catch (error) {
    console.error('Error al enviar el correo:', error);
    throw error;
  }
};


const sendEmailWithLink = async (email: string, link: string): Promise<any> => {
  try {
    const response = await axios.post(`${USERS_URL}/sendEmailWithLink`, {
      email,
      link,
    });


    console.log('Correo enviado con éxito:', response.data);

    return response.data;
  } catch (error) {

    console.error('Error al enviar el correo:', error);
    throw error;
  }
};


const sendEmailImm = async (email: string, message: string): Promise<any> => {
  try {
    const response = await axios.post(`${USERS_URL}/sendEmailImm`, {
      email,
      message,
    });

    console.log('Correo enviado con éxito:', response.data);

    return response.data;
  } catch (error) {
    console.error('Error al enviar el correo:', error);
    throw error;
  }
};


const sendEmailErrorReport = async (
  affectedTranslation: string,
  errorDescription: string,
  errorFile: string,
  staff: string,
  email: string,
  fileId: string
): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailErrorReport`, {
      affectedTranslation,
      errorDescription,
      errorFile,
      staff,
      email,
      fileId,
    })
    .then((response: AxiosResponse) => response.data)
}

const sendEmailForCaseWorkerReview = async (email: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailForCaseWorkerReview`, {
      email,
    })
    .then((response: AxiosResponse) => response.data)
}

const sendEmailForAssignedCaseWorkerForReview = async (email: string, staffEmail:string): Promise<Users | undefined> => {
  console.log('entre a la query', email , staffEmail)
  return await axios
    .post(`${USERS_URL}/sendEmailForAssignedCaseWorkerForReview`, {
      email,
      staffEmail
    })
    .then((response: AxiosResponse) => response.data)
}

const sendEmailForCaseWorkerReviewRejected = async (email: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailForCaseWorkerReviewRejected`, {
      email,
    })
    .then((response: AxiosResponse) => response.data)
}

const sendEmailForCaseWorkerReviewAproved = async (email: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailForCaseWorkerReviewAproved`, {
      email,
    })
    .then((response: AxiosResponse) => response.data)
}

const sendEmailForAssingnedCaseWorker = async (email: string, userEmail: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailForAssingnedCaseWorker`, {
      email,
      userEmail,
    })
    .then((response: AxiosResponse) => response.data)
}

const sendEmailForUserReview = async (email: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailForUserReview`, {
      email,
    })
    .then((response: AxiosResponse) => response.data)
}


const sendEmailApprovalOfLetters = async (email: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailApprovalOfLetters`, {
      email,
    })
    .then((response: AxiosResponse) => response.data);
};


const sendEmailrejectOfLetters = async (email: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailRejectOfLetters`, {
      email,
    })
    .then((response: AxiosResponse) => response.data);
};

// Request para enviar correo de revisión de cartas completada (Plantilla 58)
const sendEmailReviewOfLettersCompleted = async (authorEmail: string, userEmail: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailReviewOfLettersCompleted`, {
      authorEmail,
      userEmail, // Pass the user email in the request
    })
    .then((response: AxiosResponse) => response.data);
};




const sendEmailForUserNotifyCaseWorkerAssingned = async (email: string): Promise<Users | undefined> => {
  return await axios
    .post(`${USERS_URL}/sendEmailForUserNotifyCaseWorkerAssingned`, {
      email,
    })
    .then((response: AxiosResponse) => response.data)
}

const getTemplateSendingBluee = async (): Promise<sendingBlueTemplates[]> => {
  return await axios
    .get(`${USERS_URL}/getlistoftemplates/sblue`)
    .then((d: AxiosResponse<sendingBlueTemplatesQueryResponse>) => {
      return d.data as sendingBlueTemplates[]
    })
}

const getNewUsers = (searchFilter: IQueryUsersFilter): Promise<UsersQueryResponse> | undefined => {
  return axios
    .post(`${USERS_URL}/newusers`, {...searchFilter})
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}
const registrationNew = async (user: any): Promise<Users | undefined> => {
  console.log(user , 'request user')
  return await axios
    .post(`${API_URL}/newuser`, {firstName: user.firstName, lastName:user.lastName, email:user.email, password:user.password})
    .then((response: AxiosResponse) => response.data)

}

const getAssociatedAccounts = async (userId: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axios.get(`${ASSOCIATED_USERS_URL}/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching associated accounts:', error);
    throw error;
  }
};

// Añadir una cuenta asociada
const addAssociatedAccount = async (userOneId: any, userTwoId: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axios.post(ASSOCIATED_USERS_URL, {
      userone: userOneId,
      usertwo: userTwoId
    });
    return response.data;
  } catch (error) {
    console.error('Error adding associated account:', error);
    throw error;
  }
};

// Eliminar una cuenta asociada
const removeAssociatedAccount = async (userOneId: any, userTwoId: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axios.delete(`${ASSOCIATED_USERS_URL}/del/deleteassuser`, {
      data: { userone: userOneId, usertwo: userTwoId }
    });
    return response.data;
  } catch (error) {
    console.error('Error removing associated account:', error);
    throw error;
  }
};


export {
  sendEmailImm,
  getAssociatedAccounts,
  removeAssociatedAccount,
  addAssociatedAccount,
  registrationNew,
  sendEmailWithLink,
  getUserUsers,
  sendEmailSwitchAccount,
  getUserUsersById,
  getUserUsersByEmail,
  saveUserBasic,
  getUserByFilter,
  sendEmailBlueTemplate,
  getTemplateSendingBluee,
  getNewUsers,
  getUserNewQuery,
  sendEmailBlueTemplateExtra,
  getPartnetship,
  sendEmailErrorReport,
  sendEmailForCaseWorkerReview,
  sendEmailForUserReview,
  sendEmailForCaseWorkerReviewRejected,
  sendEmailForCaseWorkerReviewAproved,
  sendEmailForAssingnedCaseWorker,
  sendEmailForUserNotifyCaseWorkerAssingned,
  sendEmailForAssignedCaseWorkerForReview,
  getUserNewQueryDashboard,
  sendEmailApprovalOfLetters,
  sendEmailReviewOfLettersCompleted,
  sendEmailrejectOfLetters
}
