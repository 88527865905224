import {type ImmigrationCost, type ImmigrationCostQueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'
import {type Files} from '../../../../../../app/modules/apps/file/core/_models'
import {type ImmigrationService} from '../../services/core/_models'
import {type PaginationState} from '../../../../../../core/helpers'

const API_URL = process.env.REACT_APP_API_URL

const ImmigrationCost_URL = `${API_URL}/immigrationcosts`
const ImmigrationCost_document_URL = `${API_URL}/immigrationcostsdocuments`

const getImmigrationCost = async (
  paginationState?: PaginationState,
  immigrationOption?: Partial<ImmigrationCost>,
  immigrationServices?: Partial<ImmigrationService[]>
): Promise<ImmigrationCost[]> => {
  return await axios
    .post(`${ImmigrationCost_URL}/all`, {
      immigrationService: immigrationServices?.map((item) => (item?.id ? item.id : '')),
      ...paginationState,
      ...immigrationOption,
    })
    .then((response: AxiosResponse<ImmigrationCostQueryResponse>) => {
      return response.data.data as ImmigrationCost[]
    })
}

// Save  ImmigrationCost
const saveImmigrationCost = async (
  immigrationCost: ImmigrationCost
): Promise<ImmigrationCost[]> => {
  return await axios
    .post(`${ImmigrationCost_URL}`, {...immigrationCost})
    .then((response: AxiosResponse<ImmigrationCostQueryResponse>) => {
      return response.data as ImmigrationCost[]
    })
}
// Save  ImmigrationCost
const updateImmigrationCost = async (
  immigrationCost: ImmigrationCost
): Promise<ImmigrationCost[]> => {
  return await axios
    .patch(`${ImmigrationCost_URL}/${immigrationCost.id}`, {
      ...immigrationCost,
    })
    .then((response: AxiosResponse<ImmigrationCostQueryResponse>) => {
      return response.data as ImmigrationCost[]
    })
}

const getImmigrationCostFiles = async (id: string): Promise<Files[]> => {
  return await axios
    .get(`${ImmigrationCost_document_URL}/files/${id}`)
    .then((response: AxiosResponse<ImmigrationCostQueryResponse>) => {
      return response.data as Files[]
    })
}

export {getImmigrationCost, saveImmigrationCost, updateImmigrationCost, getImmigrationCostFiles}
