import {type ID, type Response} from '../../../../../core/helpers'

export interface Profile {
  id?: ID
  email: string | ''
  avatar?: string | null
  firstName?: string | null
  lastName?: string | null
  birth?: Date | null
  address?: string | null
  countryOfOrigin?: string | null
  gender?: string | null
  phone?: string | null
  whatsapp?: string | null
  nationalitie?: string | null
  language?: string | null
  timeZone?: string | null
  role?: object | null
  createdAt?: string | null
  profile?: object | null
}

export interface ProfileImmigration {
  id?: ID
  profile?: object | null
}

export type ProfileQueryResponse = Response<Profile[]>

type toFormValuesType = (apiData: Profile) => Profile

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id,
  firstName: apiData.firstName || '',
  lastName: apiData.lastName || '',
  email: apiData.email || '',
  birth: apiData.birth != null ? new Date(apiData.birth) : null,
  address: apiData.address || 'Sincelejo',
  countryOfOrigin: apiData.countryOfOrigin || 'Colombia',
  gender: apiData.gender || 'M',
  phone: apiData.phone || '123',
  whatsapp: apiData.whatsapp || '123',

  nationalitie: apiData.nationalitie || 'CO',
  language: apiData.language || 'ES',
  timeZone: apiData.timeZone || '-5',
  profile: apiData.profile != null ? JSON.stringify(apiData.profile) : {} || {},
})

export const profileDetailsInitValues: Profile = {
  avatar: '/media/avatars/300-1.jpg',
  firstName: '',
  lastName: '',
  email: '',
  birth: new Date('1900-01-01'),
  address: '',
  countryOfOrigin: '',
  gender: '',
  phone: '',
  whatsapp: '',

  nationalitie: '',

  language: '',
  timeZone: '',
  profile: {},
}
