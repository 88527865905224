import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { type FC, useContext, useEffect, lazy, Suspense, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react';

const App = lazy(() => import('../../app/App'));
const AuthPage = lazy(() => import('../../auth/AuthPage'));
const CartRoutes = lazy(() => import('../../app/pages/Cart/Routes/CartRoutes'));
const CartRoutesEmbed = lazy(() => import('../../app/pages/Cart/Routes/CartRoutesEmbed'));
const Logout = lazy(() => import('../../auth/Logout'));
const Main = lazy(() => import('../../core/layout/main'));
const MainRoutes = lazy(() => import('../../core/layout/main/MainRoutes'));
const PrivateRoutes = lazy(() => import('../../app/routing/PrivateRoutes'));
const WebRoutes = lazy(() => import('../../app/pages/Web/Routes/WebRoutes'));

const { REACT_APP_PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { isAuthenticated, loading } = rootStore.authStore;

  // Estado para manejar el splash screen y carga completa de la aplicación
  const [isAppLoading, setIsAppLoading] = useState(true);

  const profileImmigrationValue = {};
  const composepostdataValue = {
    fromUser: '',
    orderDetailId: '',
    user: '',
    email: '',
    userId: '',
    fromId: '',
    messageId: null,
    folder: '',
    draft: false,
    favorite: false,
    files_info: [],
    unread: false,
  };

  useEffect(() => {
    // Cuando termine la carga del usuario (loading === false)
    if (!loading) {
      // Esperar un poco para asegurar que la animación del splash haya terminado
      const timer = setTimeout(() => {
        const splashScreen = document.getElementById('splash-screen');
        if (splashScreen != null) {
          splashScreen.style.setProperty('display', 'none');
        }
        setIsAppLoading(false); // Indicar que la aplicación está lista para mostrarse
      }, 3000); // 3000ms o 3 segundos para asegurarse de que la animación se vea completamente

      return () => clearTimeout(timer);
    }
  }, [loading]);

  if (isAppLoading) {
    return null; // Mostrar solo el splash screen hasta que isAppLoading sea falso
  }

  return (
    <BrowserRouter basename={REACT_APP_PUBLIC_URL}>
      <Suspense fallback={<div>Cargando...</div>}>
        <Routes>
          <Route path='logout' element={<Logout />} />
          <Route element={<App />}>
            {!isAuthenticated ? (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='store/*' element={<CartRoutes />} />
                <Route path='web/*' element={<WebRoutes />} />
                <Route path='new/*' element={<Main />} />
                <Route path='embed/store/*' element={<CartRoutesEmbed />} />
                <Route
                  path='new/*'
                  element={<MainRoutes ProfileImmigration={profileImmigrationValue} />}
                />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            ) : (
              <>
                <Route
                  path='/*'
                  element={
                    <PrivateRoutes
                      ProfileImmigration={profileImmigrationValue}
                      composepostdata={composepostdataValue}
                    />
                  }
                />
                <Route path='store/*' element={<CartRoutes />} />
                <Route path='web/*' element={<WebRoutes />} />
                <Route path='embed/store/*' element={<CartRoutesEmbed />} />
                <Route index element={<Navigate to='dashboard' />} />
              </>
            )}
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default observer(AppRoutes);
