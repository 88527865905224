import {type ID, type Response} from '../../../../../core/helpers'

export interface Users {
  id?: ID
  email: string
  avatar: string | ''
  firstName: string | ''
  lastName: string | ''
  birth?: Date | null
  address: string | ''
  countryOfOrigin: string | ''
  gender: string | ''
  phone: string | ''
  whatsapp: string | ''
  nationalitie: string | ''
  language: string | ''
  timeZone: string | ''
  role?: object | null
  createdAt: string | ''
  users?: object | null
  first_service: string | ''
  last_service: string | ''
  photoId: string | ''
  photoPath: string | ''
  age: string | ''
  last_service_date_jotform_name: string | ''
  status?: string;
  dateOfBirth?: string; 

}

export interface UserBasic{
  email: string
  firstName: string
  lastName: string
  password: string

}

export interface UsersImmigration {
  id?: ID
  users?: object | null
}

export type UsersQueryResponse = Response<Users[]>

type toFormValuesType = (apiData: Users) => Users

export const toFormValues: toFormValuesType = (apiData) => ({
  id: apiData.id,
  firstName: apiData.firstName || '',
  lastName: apiData.lastName || '',
  avatar: apiData.avatar || '',
  birth: apiData.birth != null ? new Date(apiData.birth) : null,
  address: apiData.address || 'Sincelejo',
  countryOfOrigin: apiData.countryOfOrigin || 'Colombia',
  gender: apiData.gender || 'M',
  phone: apiData.phone || '123',
  whatsapp: apiData.whatsapp || '123',
  email: apiData.email || 'nodata@nodata.com',
  nationalitie: apiData.nationalitie || 'CO',
  language: apiData.language || 'ES',
  timeZone: apiData.timeZone || '-5',
  last_service: apiData.last_service || '',
  photoId: apiData.photoId || '',
  photoPath: apiData.photoPath || '',
  first_service: apiData.first_service || '',
  createdAt: apiData.createdAt || '',
  age: apiData.age || '12',
  role: apiData.role != null ? JSON.stringify(apiData.role) : {} || {},
  users: apiData.users != null ? JSON.stringify(apiData.users) : {} || {},
  last_service_date_jotform_name: apiData.last_service_date_jotform_name || '',
})

export const usersDetailsInitValues: Users = {
  avatar: '/media/avatars/300-1.jpg',
  firstName: '',
  lastName: '',

  birth: new Date('1900-01-01'),
  address: '',
  countryOfOrigin: '',
  gender: '',
  phone: '',
  whatsapp: '',
  email: '',
  nationalitie: '',

  language: '',
  timeZone: '',
  last_service: '',
  users: {},
  age: '',
  role: {},
  first_service: '',
  photoId: '',
  photoPath: '',
  createdAt: '',
  last_service_date_jotform_name: '',
}

export interface IQueryUsers {
  page?: number | null
  limit?: number | null
  search?: string
  createdAt?: string
  nationalitie?: string[]
  countryOfOrigin?: string[]
  staffName?: string[]
  firstService?: string
  lastService?: string[]
  utm?: string[]
  startDate?: string
  endDate?: string
  email?: string
  userId?: string 
}

export interface IQueryUsersFilter {
  page?: number | null
  limit?: number | null
  nationalitie?: string
  countryOfOrigin?: string
  countryOfResidence?: string
  email?: string
}

export const initialIQueryUsersFilter = {
  page: 0,
  limit: 50,
  search: '',
  countryOfOrigin: '',
  nationalitie: '',
}

export const initialIQueryUsers = {
  page: 0,
  limit: 50,
  search: '',
  countryOfOrigin: [''],
  nationalitie: [''],
}
