import {type Profile, toFormValues} from './_models'
import axios, {type AxiosResponse} from 'axios'

import {type Response} from '../../../../../core/helpers'

const API_URL = process.env.REACT_APP_API_URL
const PROFILE_URL = `${API_URL}/users`

const getUserProfile = async (userId: string): Promise<Profile> => {
  return await axios
    .get(`${PROFILE_URL}/${userId}`)
    .then((response: AxiosResponse<Response<Profile>>) => {
      return toFormValues(response.data as Profile)
    })
}

const getUserProfileById = async (userId: string): Promise<Profile | undefined> => {
  return await axios.post(`${PROFILE_URL}/data`, {id: userId}).then((response: AxiosResponse) => {
    const frmval = response.data

    return frmval[0] as Profile
  })
}

export {getUserProfile, getUserProfileById}
